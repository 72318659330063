/*
 * Copyright © 2024 AIPolls.Net. All rights reserved.
 * 
 * This software is the confidential and proprietary information of AIPolls.Net.
 * Only authorized personnel may access, modify, or deploy this software. Unauthorized 
 * access, use, or distribution outside the official repositories of AIPolls.Net is 
 * strictly prohibited.
 * 
 * The founding team and any future employees or contractors are bound by these rules.
 * 
 * This software is provided "AS IS," without any warranty, express or implied.
 */

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../api'; // Assuming Axios instance is set up
import withAuthCheck from '../hoc/withAuthCheck';
import handleErrors from '../auth/handleErrors';
import logoutIcon from '../logout_icon.png';
import profileIcon from '../profile_icon.png';
import logoIcon from '../aipolls_logo_1563x1563.png';

const ListOpinionPolls = () => {
  const [opinionPolls, setOpinionPolls] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  // Logout handler
  const handleLogout = async () => {
    try {
      await api.post('/logout'); // Logout API
      localStorage.removeItem('access_token'); // Remove access token
      navigate('/login'); // Redirect to login
    } catch (error) {
      handleErrors(error, navigate, null, setErrorMessage);
    }
  };

  const handleProfile = () => {
    navigate('/profile');
  };

  const handleDeletePoll = async (pollId) => {
    try {
      await api.delete(`/delete_opinion_poll/${pollId}`); // Delete the poll by ID
      setOpinionPolls((prevPolls) => prevPolls.filter(poll => poll.poll_id !== pollId)); // Remove the deleted poll from the list
      setErrorMessage('');
      setSuccessMessage('Poll deleted successfully.');
    } catch (error) {
      setSuccessMessage('');
      handleErrors(error, navigate, handleDeletePoll, setErrorMessage);
    }
  };

  // Fetch opinion polls
  useEffect(() => {
    const fetchOpinionPolls = async () => {
      try {
        setErrorMessage('');
        const response = await api.get('/opinion_polls'); // Fetch opinion polls
        setOpinionPolls(response.data); // Set the polls in state
        setErrorMessage('');
      } catch (error) {
        handleErrors(error, navigate, fetchOpinionPolls, setErrorMessage);
      }
    };

    fetchOpinionPolls(); // Call on component mount
  }, [navigate]);

  const handleViewPoll = (pollId) => {
    console.log(`Viewing poll with ID: ${pollId}`);
    navigate(`/viewOpinionPoll/${pollId}`); // Navigate to view the poll
  };

  const handleCancel = () => {
    navigate('/dashboard'); // Go back to dashboard
  };

  return (
    <div className="container">
      {/* Sidebar Menu */}
      <div className="sidebar">
        <div className="logo">
          <img alt="AIPolls.Net" title="AIPolls.Net" src={logoIcon} style={{ width: '100px', height: '100px' }} />
        </div>
<ul style={{ display: 'flex', flexDirection: 'column', listStyleType: 'none', padding: 0, margin: 0 }}>
    <li style={{ display: 'flex', marginTop: '20px', marginBottom: '5px', justifyContent: 'left', color: 'black' }}>
        <b>Trivia Polls</b>
    </li>
    <li style={{ display: 'flex', marginTop: '0px', marginBottom: '15px', justifyContent: 'center' }}>
      <a href="/createTriviaPoll">
        <img
          src="/Trivia.png"
          style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
          title="Trivia Polls"
          alt="Trivia Polls"
          onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
          onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
        />
      </a>&nbsp;&nbsp;
    {/*</li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
      <a href="/triviaPolls">
        <img
          src="/BrowseTrivia.png"
          style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
          title="Browse Trivia"
          alt="Browse Trivia"
          onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
          onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
        />
      </a>&nbsp;&nbsp;
    {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
      <a href="/listTriviaCampaigns">
        <img
          src="/TriviaCampaigns.png"
          style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
          title="Trivia Campaigns"
          alt="Trivia Campaigns"
          onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
          onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
        />
      </a>
    </li>
    <li style={{ display: 'flex', marginTop: '0px', marginBottom: '5px', justifyContent: 'left', color: 'black' }}>
        <b>Opinion Polls</b>
    </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}>
      <a href="/createOpinionPoll">
        <img
          src="/Opinions.png"
          style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
          title="Seek Opinions"
          alt="Seek Opinions"
          onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
          onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
        />
      </a>&nbsp;&nbsp;
    {/*</li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
      <a href="/opinionPolls">
        <img
          src="/BrowseOpinions.png"
          style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
          title="Browse Opinions"
          alt="Browse Opinions"
          onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
          onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
        />
      </a>&nbsp;&nbsp;
    {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
      <a href="/opinionCampaigns">
        <img
          src="/OpinionCampaigns.png"
          style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
          title="Opinion Campaigns"
          alt="Opinion Campaigns"
          onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
          onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
        />
      </a>
    </li>
    <li style={{ display: 'flex', marginTop: '0px', marginBottom: '5px', justifyContent: 'left', color: 'black' }}>
        <b>Market Research</b>
    </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}>
      <a href="/createResearchPoll">
        <img
          src="/ResearchPoll.png"
          style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
          title="Market Research"
          alt="Market Research"
          onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
          onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
        />
      </a>&nbsp;&nbsp;
    {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
      <a href="/researchPolls">
        <img
          src="/BrowseResearch.png"
          style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
          title="Browse Research"
          alt="Browse Research"
          onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
          onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
        />
      </a>&nbsp;&nbsp;
    {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
      <a href="/researchCampaigns">
        <img
          src="/ResearchCampaigns.png"
          style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
          title="Research Campaigns"
          alt="Research Campaigns"
          onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
          onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
        />
      </a>
    </li>
    <li style={{ display: 'flex', marginTop: '0px', marginBottom: '5px', justifyContent: 'left', color: 'black' }}>
        <b>Trivia Groups</b>
    </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}>
      <a href="/createTriviaGroup">
        <img
          src="/CreateTriviaGroup.png"
          style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
          title="Create Trivia Group"
          alt="Create Trivia Group"
          onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
          onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
        />
      </a>&nbsp;&nbsp;
    {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
      <a href="/listTriviaGroups">
        <img
          src="/ListTriviaGroups.png"
          style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
          title="List Trivia Groups"
          alt="List Trivia Groups"
          onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
          onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
        />
      </a>&nbsp;&nbsp;
    {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
      <a href="/ManageTriviaGroupPolls">
        <img
          src="/ManageTriviaGroupPoll.png"
          style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
          title="Manage Trivia Group Poll"
          alt="Manage Trivia Group Poll"
          onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
          onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
        />
      </a>
    </li>
    <li style={{ display: 'flex', marginTop: '0px', marginBottom: '5px', justifyContent: 'left', color: 'black' }}>
        <b>Opinion Groups</b>
    </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}>
      <a href="/createOpinionGroup">
        <img
          src="/CreateOpinionGroup.png"
          style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
          title="Create Opinion Group"
          alt="Create Opinion Group"
          onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
          onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
        />
      </a>&nbsp;&nbsp;
    {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
      <a href="/listOpinionGroups">
        <img
          src="/ListOpinionGroups.png"
          style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
          title="List Opinion Groups"
          alt="List Opinion Groups"
          onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
          onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
        />
      </a>&nbsp;&nbsp;
    {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
      <a href="/ManageOpinionGroupPolls">
        <img
          src="/ManageOpinionGroupPoll.png"
          style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
          title="Manage Opinion Group Poll"
          alt="Manage Opinion Group Poll"
          onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
          onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
        />
      </a>
    </li>
    <li style={{ display: 'flex', marginTop: '0px', marginBottom: '5px', justifyContent: 'left', color: 'black' }}>
        <b>Research Groups</b>
    </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}>
      <a href="/createResearchGroup">
        <img
          src="/CreateResearchGroup.png"
          style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
          title="Create Research Group"
          alt="Create Research Group"
          onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
          onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
        />
      </a>&nbsp;&nbsp;
    {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
      <a href="/listResearchGroups">
        <img
          src="/ListResearchGroups.png"
          style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
          title="List Research Groups"
          alt="List Research Groups"
          onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
          onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
        />
      </a>&nbsp;&nbsp;
    {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
      <a href="/ManageResearchGroupPolls">
        <img
          src="/ManageResearchGroupPoll.png"
          style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
          title="Manage Research Group Poll"
          alt="Manage Research Group Poll"
          onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
          onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
        />
      </a>
    </li>
  </ul>


      </div>

      {/* Main content */}
      <div className="main-content">
        <div className="header">
          <div className="logo">AIPolls.Net Opinion Polls</div>
          <div className="auth-buttons">
            <button onClick={handleLogout}>
              <img alt="Logout" title="Logout" src={logoutIcon} style={{ width: '25px', height: '25px' }} />
            </button>
            <button onClick={handleProfile}>
              <img alt="Profile" title="Profile" src={profileIcon} style={{ width: '25px', height: '25px' }} />
            </button>
          </div>
        </div>

        <h2>Your Opinion Polls</h2>

        {errorMessage && <p style={{ color: 'black' }}>{errorMessage}</p>}
        {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}

        {opinionPolls.length === 0 ? (
          <p>No opinion polls available. Click "Create Opinion Poll" to get started.</p>
        ) : (
          <ul style={{ listStyleType: 'none', padding: 0 }}>
            {opinionPolls.map((poll) => (
              <li key={poll.poll_id} style={{ marginBottom: '20px' }}>
                <strong>Question: {poll.question}</strong>
                <p>Poll ID: {poll.poll_id}</p>
                <p>Expiry: {poll.expiryHours} hours</p>
                <button onClick={() => window.open(`/openOpinionPoll/${poll.poll_id}`, '_blank')}>Open Poll</button>
                <button onClick={() => handleDeletePoll(poll.poll_id)} style={{
                    display: 'inline-block',
                    padding: '10px 20px',
                    fontSize: '16px',
                    color: '#fff',
                    backgroundColor: '#FF0000', // Red for delete button
                    border: 'none',
                    borderRadius: '5px',
                    textDecoration: 'none',
                    cursor: 'pointer',
                    marginTop: '10px'
                  }}>Delete Poll</button>
                  <button onClick={() => handleViewPoll(poll.poll_id)}>View Poll</button>
              </li>
            ))}
          </ul>
        )}

        <button onClick={handleCancel} style={{ marginTop: '20px' }}>Cancel</button>
      </div>
    </div>
  );
};

export default withAuthCheck(ListOpinionPolls);
