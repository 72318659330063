/*
 * Copyright © 2024 AIPolls.Net. All rights reserved.
 * 
 * This software is the confidential and proprietary information of AIPolls.Net.
 * Only authorized personnel may access, modify, or deploy this software. Unauthorized 
 * access, use, or distribution outside the official repositories of AIPolls.Net is 
 * strictly prohibited.
 * 
 * The founding team and any future employees or contractors are bound by these rules.
 * 
 * This software is provided "AS IS," without any warranty, express or implied.
 */

import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../api'; // Assuming Axios instance is imported
import handleErrors from '../auth/handleErrors';
import withAuthCheck from '../hoc/withAuthCheck';
import { Bar } from 'react-chartjs-2';
import logoutIcon from '../logout_icon.png';
import profileIcon from '../profile_icon.png';
import logoIcon from '../aipolls_logo_1563x1563.png';
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// Register necessary components for Chart.js
Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const ViewResearchPoll = () => {
    const [pollData, setPollData] = useState(null);
    const [groupCount, setGroupCount] = useState(0);
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: []
    }); // Initialize with empty structure
    const [researchSessionCount, setResearchSessionCount] = useState(0);
    const [singleSessionCount, setSingleSessionCount] = useState(0);
    const [totalEngagement, setTotalEngagement] = useState(0);
    const [errorMessage, setErrorMessage] = useState('');
    const { pollId } = useParams(); // Get the pollId from the URL

    const navigate = useNavigate();

    const fetchPollDetails = useCallback(async () => {
        try {
            const response = await api.get(`/view_research_poll/${pollId}`); // Adjust to match your route
            const { poll } = response.data;

            setPollData(poll);
            setGroupCount(poll.group_count);
            setResearchSessionCount(poll.research_session_count);
            setSingleSessionCount(poll.single_session_count);
            setTotalEngagement(poll.total_engagement);

            // Prepare chart data (group results by engagement)
            const groupsDistribution = poll.research_groups.reduce((acc, group) => {
                acc[group.group_name] = (acc[group.group_name] || 0);
                return acc;
            }, {});

            console.log(groupsDistribution);

            // Define a color palette for the bars
            const colorPalette = [
                'rgba(255, 99, 132, 0.8)', // Red
                'rgba(54, 162, 235, 0.8)', // Blue
                'rgba(255, 206, 86, 0.8)', // Yellow
                'rgba(75, 192, 192, 0.8)', // Green
                'rgba(153, 102, 255, 0.8)', // Purple
                'rgba(255, 159, 64, 0.8)',  // Orange
            ];

            // Use modulo to ensure that even if there are more groups than colors, it loops through the palette
            const backgroundColors = Object.keys(groupsDistribution).map(
                (_, index) => colorPalette[index % colorPalette.length]
            );

            setChartData({
                labels: Object.keys(groupsDistribution),
                datasets: [
                    {
                        label: 'Number of Sessions in Groups',
                        data: Object.values(groupsDistribution),
                        backgroundColor: backgroundColors, // Apply dynamic colors
                    },
                ],
            });
        } catch (error) {
            await handleErrors(error, navigate, fetchPollDetails, setErrorMessage);
        }
    }, [pollId, navigate]); // Adding the dependencies to the useCallback hook

    // This useEffect will update the poll details every second
    useEffect(() => {
        fetchPollDetails(); // Initial fetch

        const intervalId = setInterval(() => {
            if (!errorMessage) {
                fetchPollDetails(); // Fetch every 1 second
            }
        }, 1000);

        // Cleanup function to clear the interval on unmount
        return () => clearInterval(intervalId);
    }, [errorMessage, fetchPollDetails, pollId]);

    const handleLogout = async () => {
        try {
            await api.post('/logout'); // Call your backend logout endpoint
            localStorage.removeItem('access_token'); // Clear the access token from localStorage
            navigate('/login'); // Redirect to login after logout
        } catch (error) {
            handleErrors(error, navigate, null, setErrorMessage);
        }
    };

    const handleProfile = () => {
        // Navigate to the profile page
        navigate('/profile');
    };

    return (
        <div className="container">
            {/* Sidebar Menu */}
            <div className="sidebar">
                <div className="logo">
                    <img alt="AIPolls.Net" title="AIPolls.Net" src={logoIcon} style={{ width: '100px', height: '100px' }} />
                </div>
                <ul style={{ display: 'flex', flexDirection: 'column', listStyleType: 'none', padding: 0, margin: 0 }}>
                    <li style={{ display: 'flex', marginTop: '20px', marginBottom: '5px', justifyContent: 'left', color: 'black' }}>
                        <b>Opinion Polls</b>
                    </li>
                    <li style={{ display: 'flex', marginTop: '0px', marginBottom: '15px', justifyContent: 'center' }}>
                        <a href="/createTriviaPoll">
                            <img
                                src="/Trivia.png"
                                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                                title="Trivia Polls"
                                alt="Trivia Polls"
                                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
                            />
                        </a>&nbsp;&nbsp;
                        {/*</li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
                        <a href="/triviaPolls">
                            <img
                                src="/BrowseTrivia.png"
                                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                                title="Browse Trivia"
                                alt="Browse Trivia"
                                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
                            />
                        </a>&nbsp;&nbsp;
                        {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
                        <a href="/listTriviaCampaigns">
                            <img
                                src="/TriviaCampaigns.png"
                                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                                title="Trivia Campaigns"
                                alt="Trivia Campaigns"
                                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
                            />
                        </a>
                    </li>
                    <li style={{ display: 'flex', marginTop: '0px', marginBottom: '5px', justifyContent: 'left', color: 'black' }}>
                        <b>Opinion Polls</b>
                    </li>
                    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}>
                        <a href="/createOpinionPoll">
                            <img
                                src="/Opinions.png"
                                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                                title="Seek Opinions"
                                alt="Seek Opinions"
                                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
                            />
                        </a>&nbsp;&nbsp;
                        {/*</li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
                        <a href="/opinionPolls">
                            <img
                                src="/BrowseOpinions.png"
                                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                                title="Browse Opinions"
                                alt="Browse Opinions"
                                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
                            />
                        </a>&nbsp;&nbsp;
                        {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
                        <a href="/opinionCampaigns">
                            <img
                                src="/OpinionCampaigns.png"
                                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                                title="Opinion Campaigns"
                                alt="Opinion Campaigns"
                                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
                            />
                        </a>
                    </li>
                    <li style={{ display: 'flex', marginTop: '0px', marginBottom: '5px', justifyContent: 'left', color: 'black' }}>
                        <b>Market Research</b>
                    </li>
                    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}>
                        <a href="/createResearchPoll">
                            <img
                                src="/ResearchPoll.png"
                                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                                title="Market Research"
                                alt="Market Research"
                                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
                            />
                        </a>&nbsp;&nbsp;
                        {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
                        <a href="/researchPolls">
                            <img
                                src="/BrowseResearch.png"
                                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                                title="Browse Research"
                                alt="Browse Research"
                                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
                            />
                        </a>&nbsp;&nbsp;
                        {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
                        <a href="/researchCampaigns">
                            <img
                                src="/ResearchCampaigns.png"
                                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                                title="Research Campaigns"
                                alt="Research Campaigns"
                                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
                            />
                        </a>
                    </li>
                    <li style={{ display: 'flex', marginTop: '0px', marginBottom: '5px', justifyContent: 'left', color: 'black' }}>
                        <b>Trivia Groups</b>
                    </li>
                    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}>
                        <a href="/createTriviaGroup">
                            <img
                                src="/CreateTriviaGroup.png"
                                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                                title="Create Trivia Group"
                                alt="Create Trivia Group"
                                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
                            />
                        </a>&nbsp;&nbsp;
                        {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
                        <a href="/listTriviaGroups">
                            <img
                                src="/ListTriviaGroups.png"
                                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                                title="List Trivia Groups"
                                alt="List Trivia Groups"
                                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
                            />
                        </a>&nbsp;&nbsp;
                        {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
                        <a href="/ManageTriviaGroupPolls">
                            <img
                                src="/ManageTriviaGroupPoll.png"
                                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                                title="Manage Trivia Group Poll"
                                alt="Manage Trivia Group Poll"
                                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
                            />
                        </a>
                    </li>
                    <li style={{ display: 'flex', marginTop: '0px', marginBottom: '5px', justifyContent: 'left', color: 'black' }}>
                        <b>Opinion Groups</b>
                    </li>
                    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}>
                        <a href="/createOpinionGroup">
                            <img
                                src="/CreateOpinionGroup.png"
                                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                                title="Create Opinion Group"
                                alt="Create Opinion Group"
                                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
                            />
                        </a>&nbsp;&nbsp;
                        {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
                        <a href="/listOpinionGroups">
                            <img
                                src="/ListOpinionGroups.png"
                                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                                title="List Opinion Groups"
                                alt="List Opinion Groups"
                                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
                            />
                        </a>&nbsp;&nbsp;
                        {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
                        <a href="/ManageOpinionGroupPolls">
                            <img
                                src="/ManageOpinionGroupPoll.png"
                                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                                title="Manage Opinion Group Poll"
                                alt="Manage Opinion Group Poll"
                                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
                            />
                        </a>
                    </li>
                    <li style={{ display: 'flex', marginTop: '0px', marginBottom: '5px', justifyContent: 'left', color: 'black' }}>
                        <b>Research Groups</b>
                    </li>
                    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}>
                        <a href="/createResearchGroup">
                            <img
                                src="/CreateResearchGroup.png"
                                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                                title="Create Research Group"
                                alt="Create Research Group"
                                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
                            />
                        </a>&nbsp;&nbsp;
                        {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
                        <a href="/listResearchGroups">
                            <img
                                src="/ListResearchGroups.png"
                                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                                title="List Research Groups"
                                alt="List Research Groups"
                                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
                            />
                        </a>&nbsp;&nbsp;
                        {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
                        <a href="/ManageResearchGroupPolls">
                            <img
                                src="/ManageResearchGroupPoll.png"
                                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                                title="Manage Research Group Poll"
                                alt="Manage Research Group Poll"
                                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
                            />
                        </a>
                    </li>
                </ul>
            </div>

            {/* Main content */}
            <div className="main-content">
                <div className="header">
                    <div className="logo">AIPolls.Net Trivia Poll Details</div>
                    <div className="auth-buttons">
                        <button onClick={handleLogout}>
                            <img alt="Logout" title="Logout" src={logoutIcon} style={{ width: '25px', height: '25px' }} />
                        </button>
                        <button onClick={handleProfile}>
                            <img alt="Profile" title="Profile" src={profileIcon} style={{ width: '25px', height: '25px' }} />
                        </button>
                    </div>
                </div>

                <h2>Poll Details</h2>

                {errorMessage && <p style={{ color: 'black' }}>{errorMessage}</p>}

                {pollData ? (
                    <div>
                        <p><strong>Poll Question:</strong> {pollData.question}</p>
                        <p><strong>Number of Sessions:</strong> {researchSessionCount + singleSessionCount}</p>
                        <p><strong>Total Engagement:</strong> {totalEngagement}</p>
                        <p><strong>Number of Groups:</strong> {groupCount}</p>

                        <div>
                            <h3>Group Distribution Chart</h3>
                            {/* Only render the chart when chartData has labels */}
                            {chartData.labels.length > 0 && (
                                <Bar
                                    data={chartData}
                                    options={{
                                        responsive: true,
                                        scales: {
                                            x: {
                                                title: {
                                                    display: true,
                                                    text: 'Groups',
                                                },
                                            },
                                            y: {
                                                beginAtZero: true,
                                                title: {
                                                    display: true,
                                                    text: 'Number of Sessions',
                                                },
                                                ticks: {
                                                    precision: 0, // Ensure integer values
                                                },
                                            },
                                        },
                                    }}
                                />
                            )}
                        </div>
                    </div>
                ) : (
                    <p>Loading poll details...</p>
                )}
            </div>
        </div>
    );
};

export default withAuthCheck(ViewResearchPoll);