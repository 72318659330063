import api from '../api'; // Your Axios instance

export const refreshToken = async () => {
  try {
    const response = await api.post('/refresh_token', {}, { withCredentials: true });
    const newAccessToken = response.data.access_token;
    localStorage.setItem('access_token', newAccessToken); // Store the new access token
    return newAccessToken;
  } catch (error) {
    console.error('Refresh token failed:', error);
    return null; // Return null if refresh fails
  }
};
