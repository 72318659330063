/*
 * Copyright © 2024 AIPolls.Net. All rights reserved.
 * 
 * This software is the confidential and proprietary information of AIPolls.Net.
 * Only authorized personnel may access, modify, or deploy this software. Unauthorized 
 * access, use, or distribution outside the official repositories of AIPolls.Net is 
 * strictly prohibited.
 * 
 * The founding team and any future employees or contractors are bound by these rules.
 * 
 * This software is provided "AS IS," without any warranty, express or implied.
 */

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../api';  // Assuming Axios instance is imported
import withAuthCheck from '../hoc/withAuthCheck';
import { getCurrentTimeGMT } from '../utils/utils';
import handleErrors from '../auth/handleErrors';
import logoutIcon from '../logout_icon.png';
import profileIcon from '../profile_icon.png';
import logoIcon from '../aipolls_logo_1563x1563.png';

const ListResearchCampaigns = () => {
  const [groupCampaigns, setGroupCampaigns] = useState([]);
  const [singleCampaigns, setSingleCampaigns] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [, setIsLoggedIn] = useState(true);
  const navigate = useNavigate();

  // Logout handler
  const handleLogout = async () => {
    try {
      await api.post('/logout');  // Call backend logout endpoint
      localStorage.removeItem('access_token');  // Clear the access token from localStorage
      setIsLoggedIn(false);  // Update login state
      navigate('/login');  // Redirect to login after logout
    } catch (error) {
      handleErrors(error, navigate, null, setErrorMessage);
    }
  };

  const handleProfile = () => {
    navigate('/profile');
  };

  // Fetch research campaigns
  useEffect(() => {
    const fetchResearchCampaigns = async () => {
      try {
        setErrorMessage('');
        const response = await api.get('/list_research_campaigns');  // Fetch the research campaigns
        setGroupCampaigns(response.data.group_campaigns || []); // Ensure it's an array
        setSingleCampaigns(response.data.single_campaigns || []); // Ensure it's an array
        setErrorMessage('');  // Clear any previous error message
      } catch (error) {
        handleErrors(error, navigate, fetchResearchCampaigns, setErrorMessage);
      }
    };

    fetchResearchCampaigns();
  }, [navigate]);

  const handleViewResearchCampaign = (researchSessionId, pollId) => {
    console.log(`Viewing research session with ID: ${researchSessionId}`);
    navigate(`/viewResearchCampaign/${researchSessionId}/${pollId}`);  // Redirect to the research session page
  };

  const handleCancel = () => {
    navigate('/dashboard');
  };

  const handleViewSingleResearchCampaign = (pollId, researchSessionId) => {
    // Navigate to the route with pollId and researchSessionId
    navigate(`/viewSingleResearchResults/${pollId}/${researchSessionId}`);
  };

  const getSessionStatus = (researchSession) => {
    const sessionStartTime = new Date(researchSession.session_timestamp);
    const currentTime = new Date();
    const hoursDifference = Math.abs(currentTime - sessionStartTime) / 36e5;

    return researchSession.status === 1 ? (hoursDifference > 4 ? 'Expired' : 'Active') : 'Closed';
  };

  return (
    <div className="container">
      {/* Sidebar Menu */}
      <div className="sidebar">
        <div className="logo">
          <img alt="AIPolls.Net" title="AIPolls.Net" src={logoIcon} style={{ width: '100px', height: '100px' }} />
        </div>
        <ul style={{ display: 'flex', flexDirection: 'column', listStyleType: 'none', padding: 0, margin: 0 }}>
          <li style={{ display: 'flex', marginTop: '20px', marginBottom: '5px', justifyContent: 'left', color: 'black' }}>
            <b>Trivia Polls</b>
          </li>
          <li style={{ display: 'flex', marginTop: '0px', marginBottom: '15px', justifyContent: 'center' }}>
            <a href="/createTriviaPoll">
              <img
                src="/Trivia.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="Trivia Polls"
                alt="Trivia Polls"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>&nbsp;&nbsp;
            {/*</li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
            <a href="/triviaPolls">
              <img
                src="/BrowseTrivia.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="Browse Trivia"
                alt="Browse Trivia"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>&nbsp;&nbsp;
            {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
            <a href="/listTriviaCampaigns">
              <img
                src="/TriviaCampaigns.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="Trivia Campaigns"
                alt="Trivia Campaigns"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>
          </li>
          <li style={{ display: 'flex', marginTop: '0px', marginBottom: '5px', justifyContent: 'left', color: 'black' }}>
            <b>Opinion Polls</b>
          </li>
          <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}>
            <a href="/createOpinionPoll">
              <img
                src="/Opinions.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="Seek Opinions"
                alt="Seek Opinions"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>&nbsp;&nbsp;
            {/*</li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
            <a href="/opinionPolls">
              <img
                src="/BrowseOpinions.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="Browse Opinions"
                alt="Browse Opinions"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>&nbsp;&nbsp;
            {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
            <a href="/opinionCampaigns">
              <img
                src="/OpinionCampaigns.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="Opinion Campaigns"
                alt="Opinion Campaigns"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>
          </li>
          <li style={{ display: 'flex', marginTop: '0px', marginBottom: '5px', justifyContent: 'left', color: 'black' }}>
            <b>Market Research</b>
          </li>
          <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}>
            <a href="/createResearchPoll">
              <img
                src="/ResearchPoll.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="Market Research"
                alt="Market Research"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>&nbsp;&nbsp;
            {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
            <a href="/researchPolls">
              <img
                src="/BrowseResearch.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="Browse Research"
                alt="Browse Research"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>&nbsp;&nbsp;
            {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
            <a href="/researchCampaigns">
              <img
                src="/ResearchCampaigns.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="Research Campaigns"
                alt="Research Campaigns"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>
          </li>
          <li style={{ display: 'flex', marginTop: '0px', marginBottom: '5px', justifyContent: 'left', color: 'black' }}>
            <b>Trivia Groups</b>
          </li>
          <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}>
            <a href="/createTriviaGroup">
              <img
                src="/CreateTriviaGroup.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="Create Trivia Group"
                alt="Create Trivia Group"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>&nbsp;&nbsp;
            {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
            <a href="/listTriviaGroups">
              <img
                src="/ListTriviaGroups.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="List Trivia Groups"
                alt="List Trivia Groups"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>&nbsp;&nbsp;
            {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
            <a href="/ManageTriviaGroupPolls">
              <img
                src="/ManageTriviaGroupPoll.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="Manage Trivia Group Poll"
                alt="Manage Trivia Group Poll"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>
          </li>
          <li style={{ display: 'flex', marginTop: '0px', marginBottom: '5px', justifyContent: 'left', color: 'black' }}>
            <b>Opinion Groups</b>
          </li>
          <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}>
            <a href="/createOpinionGroup">
              <img
                src="/CreateOpinionGroup.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="Create Opinion Group"
                alt="Create Opinion Group"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>&nbsp;&nbsp;
            {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
            <a href="/listOpinionGroups">
              <img
                src="/ListOpinionGroups.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="List Opinion Groups"
                alt="List Opinion Groups"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>&nbsp;&nbsp;
            {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
            <a href="/ManageOpinionGroupPolls">
              <img
                src="/ManageOpinionGroupPoll.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="Manage Opinion Group Poll"
                alt="Manage Opinion Group Poll"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>
          </li>
          <li style={{ display: 'flex', marginTop: '0px', marginBottom: '5px', justifyContent: 'left', color: 'black' }}>
            <b>Research Groups</b>
          </li>
          <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}>
            <a href="/createResearchGroup">
              <img
                src="/CreateResearchGroup.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="Create Research Group"
                alt="Create Research Group"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>&nbsp;&nbsp;
            {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
            <a href="/listResearchGroups">
              <img
                src="/ListResearchGroups.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="List Research Groups"
                alt="List Research Groups"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>&nbsp;&nbsp;
            {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
            <a href="/ManageResearchGroupPolls">
              <img
                src="/ManageResearchGroupPoll.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="Manage Research Group Poll"
                alt="Manage Research Group Poll"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>
          </li>
        </ul>


      </div>

      {/* Main Content */}
      <div className="main-content">
        <div className="header">
          <div className="logo">AIPolls.Net Research Campaigns</div>
          <div className="auth-buttons">
            <button onClick={handleLogout}>
              <img alt="Logout" title="Logout" src={logoutIcon} style={{ width: '25px', height: '25px' }} />
            </button>
            <button onClick={handleProfile}>
              <img alt="Profile" title="Profile" src={profileIcon} style={{ width: '25px', height: '25px' }} />
            </button>
          </div>
        </div>

        <h2>Your Campaigns</h2>

        {/* Display error message if exists */}
        {errorMessage && <p style={{ color: 'black' }}>{errorMessage}</p>}


        <dl style={{ color: "black", listStyleType: 'none', padding: 0 }}>
          <div>
            <h2>Research Campaigns</h2>

            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}

            {/* Render Group Campaigns */}
            <h3>Group Campaigns</h3>
            {groupCampaigns.length > 0 ? (
              groupCampaigns.map((campaign) => (
                <div key={campaign.research_session_id} style={{ marginBottom: '20px', border: '1px solid #ddd', padding: '10px' }}>
                  <p><strong>Group Name:</strong> {campaign.group_name}</p>
                  <p><strong>Campaign ID:</strong> {campaign.research_session_id}</p>
                  <p><strong>Current Time:</strong> {getCurrentTimeGMT()}</p>
                  <p><strong>Campaign Start Date:</strong> {campaign.session_timestamp}</p>
                  <p><strong>Campaign Strength:</strong> {campaign.total_count}</p>
                  <p><strong>Poll ID:</strong> {campaign.poll_uuid}</p>
                  <p><strong>Poll Title:</strong> {campaign.poll_title}</p>
                  <p><strong>Poll Expiry:</strong> {campaign.poll_expiry}</p>
                  <p><strong>Status:</strong> {campaign.status}</p>
                  <p><strong>Poll Options:</strong></p>
                  <ul>
                    {campaign.options.map((option, index) => (
                      <li key={index}>{option}</li>
                    ))}
                  </ul>
                  <p><strong>Poll Count:</strong></p>
                  {Object.values(campaign.poll_count).every(count => count === 0) ? (
                    <p>None</p>
                  ) : (
                    <ul>
                      {Object.entries(campaign.poll_count).map(([option, count], index) => (
                        <li key={index}>{option}: {count} votes</li>
                      ))}
                    </ul>
                  )}
                  <p>
                    <button onClick={() => handleViewResearchCampaign(campaign.research_session_id, campaign.poll_uuid,)}>
                      View Group Research Campaign
                    </button>
                  </p>
                </div>
              ))
            ) : (
              <p>No group campaigns found.</p>
            )}

            {/* Render Single Campaigns */}
            <h3>Single Campaigns</h3>
            {singleCampaigns.length > 0 ? (
              singleCampaigns.map((campaign) => (
                <div key={campaign.research_session_id} style={{ marginBottom: '20px', border: '1px solid #ddd', padding: '10px' }}>
                  <p><strong>Campaign ID:</strong> {campaign.research_session_id}</p>
                  <p><strong>Current Time:</strong> {getCurrentTimeGMT()}</p>
                  <p><strong>Campaign Start Date:</strong> {campaign.session_timestamp}</p>
                  <p><strong>Campaign Strength:</strong> {campaign.total_count}</p>
                  <p><strong>Poll ID:</strong> {campaign.poll_uuid}</p>
                  <p><strong>Poll Title:</strong> {campaign.poll_title}</p>
                  <p><strong>Poll Duration:</strong> {campaign.poll_duration} hours</p>
                  <p><strong>Status:</strong> {campaign.status}</p>
                  <p><strong>Poll Options:</strong></p>
                  <ul>
                    {campaign.options.map((option, index) => (
                      <li key={index}>{option}</li>
                    ))}
                  </ul>
                  <p><strong>Poll Count:</strong></p>
                  <ul>
                    {Object.entries(campaign.poll_count).map(([option, count], index) => (
                      <li key={index}>{option}: {count} votes</li>
                    ))}
                  </ul>
                  <p>
                    <button onClick={() => handleViewSingleResearchCampaign(campaign.poll_uuid, campaign.research_session_id)}>
                      View Single Research Campaign
                    </button>
                  </p>
                </div>
              ))
            ) : (
              <p>No single campaigns found.</p>
            )}
            {errorMessage && <p style={{ color: 'black' }}>{errorMessage}</p>}
          </div>
        </dl>

        <button onClick={handleCancel} style={{ marginTop: '20px' }}>Cancel</button>
      </div>
    </div>
  );
};

export default withAuthCheck(ListResearchCampaigns);