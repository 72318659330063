/*
 * Copyright © 2024 AIPolls.Net. All rights reserved.
 * 
 * This software is the confidential and proprietary information of AIPolls.Net.
 * Only authorized personnel may access, modify, or deploy this software. Unauthorized 
 * access, use, or distribution outside the official repositories of AIPolls.Net is 
 * strictly prohibited.
 * 
 * The founding team and any future employees or contractors are bound by these rules.
 * 
 * This software is provided "AS IS," without any warranty, express or implied.
 */

// src/utils/utils.js

export function getFutureDateString(hoursAhead) {
  const now = new Date();
  const futureDate = new Date(now.getTime() + hoursAhead * 60 * 60 * 1000); // Add hours in milliseconds

  // Format the date to YYYY-MM-DDTHH:MM:SS
  return futureDate.toISOString().slice(0, 19);
}

 // Function to get the current time in GMT format
 export function getCurrentTimeGMT() {
  const now = new Date();
  return now.toUTCString();  // Convert to UTC format (GMT)
};

export const getRandomColor = () => {
  const r = Math.floor(Math.random() * 255);
  const g = Math.floor(Math.random() * 255);
  const b = Math.floor(Math.random() * 255);
  return `rgba(${r}, ${g}, ${b}, 0.8)`;  // Adjust opacity as needed
};
