/*
 * Copyright © 2024 AIPolls.Net. All rights reserved.
 * 
 * This software is the confidential and proprietary information of AIPolls.Net.
 * Only authorized personnel may access, modify, or deploy this software. Unauthorized 
 * access, use, or distribution outside the official repositories of AIPolls.Net is 
 * strictly prohibited.
 * 
 * The founding team and any future employees or contractors are bound by these rules.
 * 
 * This software is provided "AS IS," without any warranty, express or implied.
 */

import React, { useState, useEffect } from 'react';
import api from '../api'; // Assuming you have set up Axios instance
import { useNavigate } from 'react-router-dom'; // Assuming you use this to navigate after login
import handleErrors from '../auth/handleErrors';
import '../App.css';
import logoutIcon from '../logout_icon.png';
import profileIcon from '../profile_icon.png';
import logoIcon from '../aipolls_logo_1563x1563.png';
import slogan from '../aipolls_slogan.gif';

const Signup = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [qrCodeUrl, setQrCodeUrl] = useState('');  // Add state for QR code URL
  const [loginMode, setLoginMode] = useState(false);  // Add state for signup mode
  const [errorMessage, setErrorMessage] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate(); // To redirect after login
  const [acceptedAgreement, setAcceptedAgreement] = useState(false); // New state for agreement checkbox
  const [street, setStreet] = useState('');
  const [aptSuite, setAptSuite] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [country, setCountry] = useState('');
  const [mobilePhone, setMobilePhone] = useState('');
  const [workPhone, setWorkPhone] = useState('');
  const [company, setCompany] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  //const apiUrl = process.env.REACT_APP_API_URL;
  //const AWS_HOSTING = (process.env.REACT_APP_AWS_HOSTING) || '1'; // Set to '1' if hosted on AWS
  const [ip, setIp] = useState('');
  const MANOJ_IP = '100.8.210.61'; // Replace this with your IP address
  const TANISHA_IP = '174.204.130.117'; // Replace this with your IP address

  useEffect(() => {

    if (loginMode) {
      console.log('Redirecting to login page');
      navigate('/login'); // Redirect to signup page when signupMode is true
    }
    console.log('Signup mode is enabled');

    const ALLOWED_IPS = [MANOJ_IP, TANISHA_IP];
    // Fetch IP address
    fetch('https://api.ipify.org?format=json')
      .then((response) => response.json())
      .then((data) => {
        console.log('IP Address:', data.ip);
        setIp(data.ip); // Store IP address in state
        //if (!ALLOWED_IPS.includes(data.ip)) {
        if (!ALLOWED_IPS.includes(data.ip)) {
          console.error('You do not have permission to access this page.');
          navigate('/');
        }
      })
      .catch((error) => {
        console.error('Error fetching IP address:', error);
      });
  }, [loginMode, navigate]);

  // Helper function to check if a string contains control characters
  const containsControlCharacters = (password) => {
    const controlCharacterRegex = /[\p{C}]/u; // \p{C} matches control characters using Unicode properties
    return controlCharacterRegex.test(password);
  };

  // Function to validate the password
  const validatePassword = (password, email, name) => {
    const passwordRulesMessage = `
1. Does not start with a numeral
2. Must have at least 1 uppercase character
3. Must have at least 1 special character from the following: ('$', '@', '^', '-', '_', '%', '?', '&', '!', '*', '(', ')', '[', ']', '{', '}', '|', '~', '<', '>', ';', ':', '+', '#')
4. Must not contain any space characters
5. Must not contain any control or non-printable characters
6. Must not contain your email address or name in any upper or lower case combinations
`;

    const specialChars = ['$', '@', '^', '-', '_', '%', '?', '&', '!', '*', '(', ')', '[', ']', '{', '}', '|', '~', '<', '>', ';', ':', '+', '#'];

    // Rule 1: Password must not start with a numeral
    if (/^\d/.test(password)) {
      return `Password does not meet the following requirements: ${passwordRulesMessage}`;
    }

    // Rule 2: Must contain at least 1 uppercase letter
    if (!/[A-Z]/.test(password)) {
      return `Password does not meet the following requirements: ${passwordRulesMessage}`;
    }

    // Rule 3: Must contain at least 1 special character
    if (!specialChars.some(char => password.includes(char))) {
      return `Password does not meet the following requirements: ${passwordRulesMessage}`;
    }

    // Rule 4: Must not contain any space characters
    if (/\s/.test(password)) {
      return `Password does not meet the following requirements: ${passwordRulesMessage}`;
    }

    // Rule 5: Must not contain any control or non-printable characters
    if (containsControlCharacters(password)) {
      return `Password does not meet the following requirements: ${passwordRulesMessage}`;
    }

    // Rule 6: Must not contain the email or name (case-insensitive)
    const emailLower = email.toLowerCase();
    const nameLower = name.toLowerCase();
    const passwordLower = password.toLowerCase();

    if (passwordLower.includes(emailLower) || passwordLower.includes(nameLower)) {
      return `Password does not meet the following requirements: ${passwordRulesMessage}`;
    }

    return null; // Password is valid
  };

  // Handle user signup
  const handleSignup = async (e) => {
    e.preventDefault();

    // Check if passwords match before submitting
    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match.");
      return;
    }

    // Validate password
    const validationError = validatePassword(password, email, name);
    if (validationError) {
      setErrorMessage(validationError);
      return;
    }

    // Ensure the user has accepted the agreement
    if (!acceptedAgreement) {
      setErrorMessage('You must accept the disclosure agreement before signing up.');
      return;
    }

    console.log('Submitting signup form...');

    try {
      //const response = await api.post('https://192.168.1.188/api/signup', {
      const response = await api.post('/signup', {
        name,
        email,
        password,
        street,
        apt_suite: aptSuite,
        city,
        state,
        zip_code: zipCode,
        country,
        mobile_phone: mobilePhone,
        work_phone: workPhone,
        company,
        job_title: jobTitle,
        accepted_agreement: acceptedAgreement
      }, {
        withCredentials: true  // Include cookies with the request
      });

      console.log('Signup successful:');
      if (response === null) {
        console.log('Response is null');
      } else {
        console.log(response);
        if (response.status === 200) {
          console.log('Signup successful');
          // Redirect to login page after successful signup
          // The response should return the QR code as an image URL
          const qrCodeURL = response.data.qr_code_image;  // This contains the base64-encoded image
          setQrCodeUrl(qrCodeURL);  // Set the state with the base64-encoded QR code
          setErrorMessage('');
        } else {
          console.log('Signup failed');
          setErrorMessage('Signup failed. Please try again.');
        }
      }

    } catch (error) {
      handleErrors(error, navigate, null, setErrorMessage);
    }
  };

  return (
    <div className="container">
      {/* Sidebar Menu */}
      <div className="sidebar">
        <div className="logo">
          {(
            <img alt="AIPolls.Net" title="AIPolls.Net" src={logoIcon} style={{ width: '100px', height: '100px' }} />
          )}
        </div>
        <ul style={{ display: 'flex', flexDirection: 'column', listStyleType: 'none', padding: 0, margin: 0 }}>
          <li style={{ display: 'flex', marginTop: '20px', marginBottom: '5px', justifyContent: 'left', color: 'black' }}>
            <b>Trivia Polls</b>
          </li>
          <li style={{ display: 'flex', marginTop: '0px', marginBottom: '15px', justifyContent: 'center' }}>
            <a href="/createTriviaPoll">
              <img
                src="/Trivia.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="Trivia Polls"
                alt="Trivia Polls"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>&nbsp;&nbsp;
            {/*</li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
            <a href="/triviaPolls">
              <img
                src="/BrowseTrivia.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="Browse Trivia"
                alt="Browse Trivia"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>&nbsp;&nbsp;
            {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
            <a href="/listTriviaCampaigns">
              <img
                src="/TriviaCampaigns.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="Trivia Campaigns"
                alt="Trivia Campaigns"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>
          </li>
          <li style={{ display: 'flex', marginTop: '0px', marginBottom: '5px', justifyContent: 'left', color: 'black' }}>
            <b>Opinion Polls</b>
          </li>
          <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}>
            <a href="/createOpinionPoll">
              <img
                src="/Opinions.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="Seek Opinions"
                alt="Seek Opinions"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>&nbsp;&nbsp;
            {/*</li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
            <a href="/opinionPolls">
              <img
                src="/BrowseOpinions.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="Browse Opinions"
                alt="Browse Opinions"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>&nbsp;&nbsp;
            {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
            <a href="/opinionCampaigns">
              <img
                src="/OpinionCampaigns.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="Opinion Campaigns"
                alt="Opinion Campaigns"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>
          </li>
          <li style={{ display: 'flex', marginTop: '0px', marginBottom: '5px', justifyContent: 'left', color: 'black' }}>
            <b>Market Research</b>
          </li>
          <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}>
            <a href="/createResearchPoll">
              <img
                src="/ResearchPoll.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="Market Research"
                alt="Market Research"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>&nbsp;&nbsp;
            {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
            <a href="/researchPolls">
              <img
                src="/BrowseResearch.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="Browse Research"
                alt="Browse Research"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>&nbsp;&nbsp;
            {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
            <a href="/researchCampaigns">
              <img
                src="/ResearchCampaigns.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="Research Campaigns"
                alt="Research Campaigns"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>
          </li>
          <li style={{ display: 'flex', marginTop: '0px', marginBottom: '5px', justifyContent: 'left', color: 'black' }}>
            <b>Trivia Groups</b>
          </li>
          <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}>
            <a href="/createTriviaGroup">
              <img
                src="/CreateTriviaGroup.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="Create Trivia Group"
                alt="Create Trivia Group"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>&nbsp;&nbsp;
            {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
            <a href="/listTriviaGroups">
              <img
                src="/ListTriviaGroups.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="List Trivia Groups"
                alt="List Trivia Groups"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>&nbsp;&nbsp;
            {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
            <a href="/ManageTriviaGroupPolls">
              <img
                src="/ManageTriviaGroupPoll.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="Manage Trivia Group Poll"
                alt="Manage Trivia Group Poll"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>
          </li>
          <li style={{ display: 'flex', marginTop: '0px', marginBottom: '5px', justifyContent: 'left', color: 'black' }}>
            <b>Opinion Groups</b>
          </li>
          <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}>
            <a href="/createOpinionGroup">
              <img
                src="/CreateOpinionGroup.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="Create Opinion Group"
                alt="Create Opinion Group"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>&nbsp;&nbsp;
            {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
            <a href="/listOpinionGroups">
              <img
                src="/ListOpinionGroups.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="List Opinion Groups"
                alt="List Opinion Groups"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>&nbsp;&nbsp;
            {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
            <a href="/ManageOpinionGroupPolls">
              <img
                src="/ManageOpinionGroupPoll.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="Manage Opinion Group Poll"
                alt="Manage Opinion Group Poll"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>
          </li>
          <li style={{ display: 'flex', marginTop: '0px', marginBottom: '5px', justifyContent: 'left', color: 'black' }}>
            <b>Research Groups</b>
          </li>
          <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}>
            <a href="/createResearchGroup">
              <img
                src="/CreateResearchGroup.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="Create Research Group"
                alt="Create Research Group"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>&nbsp;&nbsp;
            {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
            <a href="/listResearchGroups">
              <img
                src="/ListResearchGroups.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="List Research Groups"
                alt="List Research Groups"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>&nbsp;&nbsp;
            {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
            <a href="/ManageResearchGroupPolls">
              <img
                src="/ManageResearchGroupPoll.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="Manage Research Group Poll"
                alt="Manage Research Group Poll"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>
          </li>
        </ul>


      </div>

      {/* Main content */}
      <div className="main-content">
        <div className="header">
          <div className="logo">AIPolls.Net Analytics Dashboard</div>
          <div className="auth-buttons">
            {(
              <button>
                <img alt="Logout" title="Logout" src={logoutIcon} style={{ width: '25px', height: '25px' }} />
              </button>
            )}
            {(
              <button>
                <img alt="Profile" title="Profile" src={profileIcon} style={{ width: '25px', height: '25px' }} />
              </button>
            )}
          </div>
        </div>


        <h2>Signup</h2>
        {errorMessage && <p style={{ color: 'black' }}>{errorMessage}</p>}
        <form onSubmit={handleSignup} style={{ display: 'flex', flexDirection: 'column', maxWidth: '600px' }}>
          {/* Name Field */}
          <div style={{ marginBottom: '10px' }}>

            <label htmlFor="name" style={{ marginRight: '10px' }}>
              Name <span style={{ color: 'red' }}>*</span>
            </label>
            <input
              type="text"
              id="name"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              style={{ padding: '8px' }}
            />
          </div>

          {/* Email Field */}
          <div style={{ marginBottom: '10px' }}>
            <label htmlFor="email" style={{ marginRight: '10px' }}>
              Email <span style={{ color: 'red' }}>*</span>
            </label>
            <input
              type="email"
              id="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              style={{ padding: '8px' }}
            />
          </div>

          {/* Password Field */}
          <div style={{ marginBottom: '10px' }}>
            <label htmlFor="password" style={{ marginRight: '10px' }}>
              Password <span style={{ color: 'red' }}>*</span>
            </label>
            <input
              type="password"
              id="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              style={{ padding: '8px' }}
            />
          </div>

          {/* Confirm Password Field */}
          <div style={{ marginBottom: '10px' }}>
            <label htmlFor="confirmPassword" style={{ marginRight: '10px' }}>
              Confirm Password <span style={{ color: 'red' }}>*</span>
            </label>
            <input
              type="password"
              id="confirmPassword"
              placeholder="Re-enter Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              style={{ padding: '8px' }}
            />
          </div>

          {/* Address Details */}
          <div style={{ marginBottom: '10px' }}>
            <label htmlFor="street" style={{ marginRight: '10px' }}>Street</label>
            <input
              type="text"
              id="street"
              placeholder="Street"
              value={street}
              onChange={(e) => setStreet(e.target.value)}
              style={{ padding: '8px' }}
            />
          </div>

          <div style={{ marginBottom: '10px' }}>
            <label htmlFor="aptSuite" style={{ marginRight: '10px' }}>Apt/Suite</label>
            <input
              type="text"
              id="aptSuite"
              placeholder="Apt/Suite"
              value={aptSuite}
              onChange={(e) => setAptSuite(e.target.value)}
              style={{ padding: '8px' }}
            />
          </div>

          <div style={{ marginBottom: '10px' }}>
            <label htmlFor="city" style={{ marginRight: '10px' }}>City</label>
            <input
              type="text"
              id="city"
              placeholder="City"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              style={{ padding: '8px' }}
            />
          </div>

          <div style={{ marginBottom: '10px' }}>
            <label htmlFor="state" style={{ marginRight: '10px' }}>State</label>
            <input
              type="text"
              id="state"
              placeholder="State"
              value={state}
              onChange={(e) => setState(e.target.value)}
              style={{ padding: '8px' }}
            />
          </div>

          <div style={{ marginBottom: '10px' }}>
            <label htmlFor="zipCode" style={{ marginRight: '10px' }}>
              Zip Code <span style={{ color: 'red' }}>*</span>
            </label>
            <input
              type="text"
              id="zipCode"
              placeholder="Zip Code"
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
              required
              style={{ padding: '8px' }}
            />
          </div>

          <div style={{ marginBottom: '10px' }}>
            <label htmlFor="country" style={{ marginRight: '10px' }}>Country</label>
            <input
              type="text"
              id="country"
              placeholder="Country"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              style={{ padding: '8px' }}
            />
          </div>

          {/* Contact Details */}
          <div style={{ marginBottom: '10px' }}>
            <label htmlFor="mobilePhone" style={{ marginRight: '10px' }}>
              Mobile Phone <span style={{ color: 'red' }}>*</span>
            </label>
            <input
              type="text"
              id="mobilePhone"
              placeholder="Mobile Phone"
              value={mobilePhone}
              onChange={(e) => setMobilePhone(e.target.value)}
              required
              style={{ padding: '8px' }}
            />
          </div>

          <div style={{ marginBottom: '10px' }}>
            <label htmlFor="workPhone" style={{ marginRight: '10px' }}>Work Phone</label>
            <input
              type="text"
              id="workPhone"
              placeholder="Work Phone"
              value={workPhone}
              onChange={(e) => setWorkPhone(e.target.value)}
              style={{ padding: '8px' }}
            />
          </div>

          {/* Professional Details */}
          <div style={{ marginBottom: '10px' }}>
            <label htmlFor="company" style={{ marginRight: '10px' }}>Company</label>
            <input
              type="text"
              id="company"
              placeholder="Company"
              value={company}
              onChange={(e) => setCompany(e.target.value)}
              style={{ padding: '8px' }}
            />
          </div>

          <div style={{ marginBottom: '10px' }}>
            <label htmlFor="jobTitle" style={{ marginRight: '10px' }}>Job Title</label>
            <input
              type="text"
              id="jobTitle"
              placeholder="Job Title"
              value={jobTitle}
              onChange={(e) => setJobTitle(e.target.value)}
              style={{ padding: '8px' }}
            />
          </div>

          {/* Checkbox to accept the agreement */}
          <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
            <input
              type="checkbox"
              checked={acceptedAgreement}
              onChange={(e) => setAcceptedAgreement(e.target.checked)}
              id="accept-agreement"
              style={{ marginRight: '10px' }}
            />
            <label htmlFor="accept-agreement">
              I accept the <a href={`/disclosure_agreement`} target="_blank" rel="noopener noreferrer">disclosure agreement</a>
            </label>
          </div>

          <button type="submit" style={{ padding: '10px', backgroundColor: '#007BFF', color: 'white', border: 'none', borderRadius: '5px' }}>Signup</button>
        </form>


        {/* Display QR code for Google Authenticator setup */}
        {qrCodeUrl && (
          <div>
            <h3>Scan the QR code with Google Authenticator:</h3>
            {/*<img src={qrCodeUrl} alt="QR code for Google Authenticator" />*/}
            <img
              src={qrCodeUrl}
              alt="QR code for Google Authenticator"
              style={{ width: '96px', height: '96px' }} // 1 inch by 1 inch
            />
          </div>
        )}
        <p />
        <button onClick={() => setLoginMode(true)}>Already have an account? Login</button>
        {/* Display animated GIF below the form */}
        <div style={{ marginTop: '20px', textAlign: 'center' }}>
          <img
            src={slogan}
            alt="Signup Slogan"
            style={{ width: '300px', height: 'auto' }}
          />
        </div>
      </div>
    </div>
  );
};

export default Signup;
