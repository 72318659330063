/*
 * Copyright © 2024 AIPolls.Net. All rights reserved.
 * 
 * This software is the confidential and proprietary information of AIPolls.Net.
 * Only authorized personnel may access, modify, or deploy this software. Unauthorized 
 * access, use, or distribution outside the official repositories of AIPolls.Net is 
 * strictly prohibited.
 * 
 * The founding team and any future employees or contractors are bound by these rules.
 * 
 * This software is provided "AS IS," without any warranty, express or implied.
 */

import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Login from './components/Login';
import Signup from './components/Signup';
import Dashboard from './components/Dashboard';
import CreateTriviaPoll from './components/CreateTriviaPoll';
import CreateOpinionPoll from './components/CreateOpinionPoll';
import CreateResearchPoll from './components/CreateResearchPoll';
import CreateTriviaGroup from './components/CreateTriviaGroup';
import CreateOpinionGroup from './components/CreateOpinionGroup';
import CreateResearchGroup from './components/CreateResearchGroup';
import ListTriviaPolls from './components/ListTriviaPolls';
import ListOpinionPolls from './components/ListOpinionPolls';
import ListOpinionGroups from './components/ListOpinionGroups';
import ListResearchPolls from './components/ListResearchPolls';
import ListResearchGroups from './components/ListResearchGroups';
import ListTriviaGroups from './components/ListTriviaGroups';
import ListTriviaCampaigns from './components/ListTriviaCampaigns';
import ListResearchCampaigns from './components/ListResearchCampaigns';
import ListOpinionCampaigns from './components/ListOpinionCampaigns';
import ManageOpinionGroupPolls from './components/ManageOpinionGroupPolls';
import ManageTriviaGroupPolls from './components/ManageTriviaGroupPolls';
import ManageResearchGroupPolls from './components/ManageResearchGroupPolls';
import ViewTriviaCampaign from './components/ViewTriviaCampaign';
import ViewOpinionCampaign from './components/ViewOpinionCampaign';
import ViewResearchCampaign from './components/ViewResearchCampaign';
import DeleteTriviaPoll from './components/DeleteTriviaPoll';
import StartTriviaGroupPoll from './components/StartTriviaGroupPoll';
import StartOpinionGroupPoll from './components/StartOpinionGroupPoll';
import StartResearchGroupPoll from './components/StartResearchGroupPoll';
import Logout from './components/Logout';
import Profile from './components/Profile';
import SingleOpinionPollResult from './components/ViewSingleOpinionCampaign';
import ViewSingleTriviaCampaign from './components/ViewSingleTriviaCampaign';
import ViewSingleOpinionCampaign from './components/ViewSingleOpinionCampaign';
import ViewSingleResearchCampaign from './components/ViewSingleResearchCampaign';
import ViewTriviaPoll from './components/ViewTriviaPoll';
import ViewOpinionPoll from './components/ViewOpinionPoll';
import ViewResearchPoll from './components/ViewResearchPoll';
import handleErrors from './auth/handleErrors'; // Your error handling function
import api from './api'; // Your Axios instance
import './App.css';
import OpenTriviaPoll from './components/OpenTriviaPoll';
import OpenOpinionPoll from './components/OpenOpinionPoll';
import OpenResearchPoll from './components/OpenResearchPoll';
import AdvanceTriviaGroupPoll from './components/AdvanceTriviaGroupPoll';
import AdvanceOpinionGroupPoll from './components/AdvanceOpinionGroupPoll';
import AdvanceResearchGroupPoll from './components/AdvanceResearchGroupPoll';
import ViewSingleOpinionResults from './components/ViewSingleOpinionResults';
import ViewSingleTriviaResults from './components/ViewSingleTriviaResults';
import ViewSingleResearchResults from './components/ViewSingleResearchResults';

//const apiUrl = process.env.REACT_APP_AUTHOR_URL;
//const apiUrl = "https://nginx_poll_author";
//const apiUrl = ""

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

   // Function to check if the user is authenticated
   useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        // Assuming an API call to check authentication status
        //console.log("PollAuthorApp CALLING AUTH CHECK!!!!! NOT SURE HOW THIS WORKS OR DOES IT?", `${apiUrl}/auth_check`);
        const response = await api.get(`/auth_check`);
        if (response.data.isAuthenticated) {
          //console.log("THIS AUTH WORKS!!!!");
          setIsAuthenticated(true);
        } else {
          //console.log("THIS AUTH DOES NOT WORK!!!!");
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.log("THIS AUTH ERROR!!!!");
        setIsAuthenticated(false);
        await handleErrors(error, null, null, null); // Handle any errors in authentication check
      } finally {
        setLoading(false); // End the loading state after checking authentication
      }
    };

    // Only check authentication status if not on login or signup page
    if (window.location.pathname !== '/login' && window.location.pathname !== '/signup'
      && window.location.pathname !== '/disclosure_agreement'
    ) {
      checkAuthStatus(); // Check authentication on component mount
    } else {
      setLoading(false); // Skip loading when on login or signup
    }
    //checkAuthStatus(); // Check authentication on component mount
  }, []);

  if (loading) {
    return <p>Loading...</p>; // Display a loading message while checking authentication
  }

  return (
    <Router>
      <Routes>
        {/* Redirect to dashboard if authenticated, otherwise login */}
        <Route path="/" element={<Navigate to={isAuthenticated ? "/dashboard" : "/login"} />} />
        <Route path="/login" element={isAuthenticated ? <Navigate to="/dashboard" /> : <Login />} />
        {/* <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={<Login />} /> */}
        <Route path="/signup" element={<Signup />} />
        <Route path="advanceTriviaGroupPoll/:poll_session_id" element={<AdvanceTriviaGroupPoll />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/createTriviaPoll" element={<CreateTriviaPoll />} />
        <Route path="/createOpinionPoll" element={<CreateOpinionPoll />} />
        <Route path="/createResearchPoll" element={<CreateResearchPoll />} />
        <Route path="/triviaPolls" element={<ListTriviaPolls />} />
        <Route path="/researchGroups" element={<ListResearchGroups />} />
        <Route path="/listTriviaGroups" element={<ListTriviaGroups />} />
        <Route path="/listOpinionGroups" element={<ListOpinionGroups />} />
        <Route path="/listResearchGroups" element={<ListResearchGroups />} />
        <Route path="/opinionPolls" element={<ListOpinionPolls />} />
        <Route path="/researchPolls" element={<ListResearchPolls />} />
        <Route path="/listTriviaCampaigns" element={<ListTriviaCampaigns />} />
        <Route path="/manageOpinionGroupPolls" element={<ManageOpinionGroupPolls />} />
        <Route path="/manageResearchGroupPolls" element={<ManageResearchGroupPolls />} />
        <Route path="/manageTriviaGroupPolls" element={<ManageTriviaGroupPolls />} />
        <Route path="/researchCampaigns" element={<ListResearchCampaigns />} />
        <Route path="/opinionCampaigns" element={<ListOpinionCampaigns />} />
        <Route path="/createTriviaGroup" element={<CreateTriviaGroup />} />
        <Route path="/createOpinionGroup" element={<CreateOpinionGroup />} />
        <Route path="/opinionGroups" element={<ListOpinionGroups />} />
        <Route path="/createResearchGroup" element={<CreateResearchGroup />} />
        <Route path="/viewTriviaCampaign/:sessionId/:pollId" element={<ViewTriviaCampaign />} />
        <Route path="/viewOpinionCampaign/:sessionId/:pollId" element={<ViewOpinionCampaign />} />
        <Route path="/viewResearchCampaign/:sessionId/:pollId" element={<ViewResearchCampaign />} />
        <Route path="/deleteTriviaPoll/:pollId" element={<DeleteTriviaPoll />} />
        <Route path="/startTriviaGroupPoll/:group_uuid" element={<StartTriviaGroupPoll />} />
        <Route path="/startOpinionGroupPoll/:group_uuid" element={<StartOpinionGroupPoll />} />
        <Route path="/startResearchGroupPoll/:group_uuid" element={<StartResearchGroupPoll />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/singleOpinionPollResult/:sessionId" element={<SingleOpinionPollResult />} />
        <Route path="viewSingleTriviaCampaign/:sessionId" element={<ViewSingleTriviaCampaign />} />
        <Route path="viewSingleOpinionCampaign/:sessionId" element={<ViewSingleOpinionCampaign />} />
        <Route path="viewSingleResearchCampaign/:sessionId" element={<ViewSingleResearchCampaign />} />
        <Route path="viewTriviaPoll/:pollId" element={<ViewTriviaPoll />} />
        <Route path="viewOpinionPoll/:pollId" element={<ViewOpinionPoll />} />
        <Route path="viewResearchPoll/:pollId" element={<ViewResearchPoll />} />
        <Route path="openTriviaPoll/:pollId" element={<OpenTriviaPoll />} />
        <Route path="openOpinionPoll/:pollId" element={<OpenOpinionPoll />} />
        <Route path="openResearchPoll/:pollId" element={<OpenResearchPoll />} />
        <Route path="advanceTriviaGroupPoll/:poll_session_id" element={<AdvanceTriviaGroupPoll />} />
        <Route path="advanceOpinionGroupPoll/:poll_session_id" element={<AdvanceOpinionGroupPoll />} /> 
        <Route path="advanceResearchGroupPoll/:poll_session_id" element={<AdvanceResearchGroupPoll />} />
        <Route path="viewSingleOpinionResults/:pollId/:opinionSessionId" element={<ViewSingleOpinionResults />} />
        <Route path="viewSingleTriviaResults/:pollId/:triviaSessionId" element={<ViewSingleTriviaResults />} />
        <Route path="viewSingleResearchResults/:pollId/:researchSessionId" element={<ViewSingleResearchResults />} />
      </Routes>

    </Router>
  );
};

export default App;
