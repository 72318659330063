import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../api'; // Assuming Axios instance is imported
import handleErrors from '../auth/handleErrors';
import withAuthCheck from '../hoc/withAuthCheck';
import { getRandomColor } from '../utils/utils'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Bar } from 'react-chartjs-2';
import logoutIcon from '../logout_icon.png';
import profileIcon from '../profile_icon.png';
import logoIcon from '../aipolls_logo_1563x1563.png';
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// Register necessary components for Chart.js
Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const ViewSingleOpinionResults = () => {
    const [poll_author, setPollAuthor] = useState(''); // Store the poll author
    const [totalVotes, setTotalVotes] = useState(0); // Store the total number of votes
    const [question, setQuestion] = useState(''); // Store the poll question
    const [chartData, setChartData] = useState(null);
    const [campaignStartDate, setCampaignStartDate] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const { pollId, opinionSessionId } = useParams(); // Get the session UUID from the URL

    const navigate = useNavigate();

    const fetchPollResults = useCallback(async () => {
        try {
            // Fetch poll options and results for the session
            const response = await api.get(`/get_single_opinion_results/${pollId}/${opinionSessionId}`); // Adjust to match your route
            const { options, voteDistribution } = response.data; // Assuming you return options and vote counts
            setTotalVotes(response.data.totalVotes);
            setQuestion(response.data.question);
            setCampaignStartDate(response.data.start_date);
            setPollAuthor(response.data.name);

            // Prepare chart data
            /*const colorPalette = [
                'rgba(255, 99, 132, 0.8)',  // Red
                'rgba(54, 162, 235, 0.8)',  // Blue
                'rgba(255, 206, 86, 0.8)',  // Yellow
                'rgba(75, 192, 192, 0.8)',  // Green
            ];

            setChartData({
                labels: options, // The options like A, B, C, D
                datasets: [
                    {
                        label: 'Number of Votes',
                        data: voteDistribution, // The number of votes per option
                        backgroundColor: colorPalette.slice(0, options.length), // Use dynamic colors based on options count
                    },
                ],
            });*/

            const backgroundColors = options.map(() => getRandomColor());
            
            setChartData({
                labels: options, // The options like A, B, C, D
                datasets: [
                    {
                        label: 'Number of Votes',
                        data: voteDistribution, // The number of votes per option
                        backgroundColor: backgroundColors, // Apply dynamic colors based on options count
                    },
                ],
            });
        } catch (error) {
            await handleErrors(error, navigate, fetchPollResults, setErrorMessage);
        }
    }, [pollId, opinionSessionId, navigate]);

    const handleLogout = async () => {
        try {
          await api.post('/logout'); // Call your backend logout endpoint
          localStorage.removeItem('access_token'); // Clear the access token from localStorage
          navigate('/login'); // Redirect to login after logout
        } catch (error) {
          handleErrors(error, navigate, null, setErrorMessage);
        }
      };
    
      const handleProfile = () => {
        navigate('/profile');
      };

    // Fetch poll results every second
    useEffect(() => {
        const intervalId = setInterval(fetchPollResults, 1000); // Call fetchPollResults every 1000 ms (1 second)

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
    }, [fetchPollResults]);

    // Function to generate and download the PDF report
    const handleDownloadReport = async () => {
        // Generate the chart as an image
        const chartCanvas = document.getElementById('poll-results-chart');
        const chartImage = await html2canvas(chartCanvas).then((canvas) => canvas.toDataURL('image/png'));

        // Create a PDF document
        const doc = new jsPDF();

        // Set metadata for the PDF
        doc.setProperties({
            title: 'AIPolls.Net Opinion Poll Chart',
            subject: `${question}`,
            author: `${poll_author}`,
            keywords: 'poll, results, AIPolls.Net',
            creator: 'AIPolls.Net'
        });
        // Add poll question and vote data to the PDF
        doc.setFontSize(10);
        doc.setFont('helvetica', 'bold');
        doc.text(`Poll Question: ${question}`, 10, 10);
        doc.setFontSize(8);
        doc.setFont('helvetica', 'normal');
        doc.text(`Poll ID: ${pollId}`, 10, 15);
        doc.setFont('helvetica', 'normal');
        doc.text(`Opinion Campaign ID: ${opinionSessionId}`, 10, 20);
        doc.setFont('helvetica', 'normal');
        doc.text(`Campaign Start Date: ${campaignStartDate}`, 10, 25);
        doc.text(`Campaign Strength: ${totalVotes} participants`, 10, 30);
        doc.setFont('helvetica', 'bold');
        doc.text(`Report Author: ${poll_author}`, 10, 35);
        doc.setFont('helvetica', 'italic');
        doc.text(`Report Generated: ${new Date().toLocaleString()}`, 10, 40);

        // Add the chart image to the PDF
        doc.addImage(chartImage, 'PNG', 10, 40, 180, 100); // Position and size the chart

        // Save the PDF
        doc.save(`poll_results_${pollId}.pdf`);
    };

    return (
        <div className="container">
            {/* Sidebar Menu */}
            <div className="sidebar">
                <div className="logo">
                    <img alt="AIPolls.Net" title="AIPolls.Net" src={logoIcon} style={{ width: '100px', height: '100px' }} />
                </div>
                <ul style={{ display: 'flex', flexDirection: 'column', listStyleType: 'none', padding: 0, margin: 0 }}>
                    <li style={{ display: 'flex', marginTop: '20px', marginBottom: '5px', justifyContent: 'left', color: 'black' }}>
                        <b>Trivia Polls</b>
                    </li>
                    <li style={{ display: 'flex', marginTop: '0px', marginBottom: '15px', justifyContent: 'center' }}>
                        <a href="/createTriviaPoll">
                            <img
                                src="/Trivia.png"
                                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                                title="Trivia Polls"
                                alt="Trivia Polls"
                                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
                            />
                        </a>&nbsp;&nbsp;
                        {/*</li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
                        <a href="/triviaPolls">
                            <img
                                src="/BrowseTrivia.png"
                                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                                title="Browse Trivia"
                                alt="Browse Trivia"
                                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
                            />
                        </a>&nbsp;&nbsp;
                        {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
                        <a href="/listTriviaCampaigns">
                            <img
                                src="/TriviaCampaigns.png"
                                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                                title="Trivia Campaigns"
                                alt="Trivia Campaigns"
                                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
                            />
                        </a>
                    </li>
                    <li style={{ display: 'flex', marginTop: '0px', marginBottom: '5px', justifyContent: 'left', color: 'black' }}>
                        <b>Opinion Polls</b>
                    </li>
                    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}>
                        <a href="/createOpinionPoll">
                            <img
                                src="/Opinions.png"
                                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                                title="Seek Opinions"
                                alt="Seek Opinions"
                                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
                            />
                        </a>&nbsp;&nbsp;
                        {/*</li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
                        <a href="/opinionPolls">
                            <img
                                src="/BrowseOpinions.png"
                                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                                title="Browse Opinions"
                                alt="Browse Opinions"
                                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
                            />
                        </a>&nbsp;&nbsp;
                        {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
                        <a href="/opinionCampaigns">
                            <img
                                src="/OpinionCampaigns.png"
                                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                                title="Opinion Campaigns"
                                alt="Opinion Campaigns"
                                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
                            />
                        </a>
                    </li>
                    <li style={{ display: 'flex', marginTop: '0px', marginBottom: '5px', justifyContent: 'left', color: 'black' }}>
                        <b>Market Research</b>
                    </li>
                    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}>
                        <a href="/createResearchPoll">
                            <img
                                src="/ResearchPoll.png"
                                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                                title="Market Research"
                                alt="Market Research"
                                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
                            />
                        </a>&nbsp;&nbsp;
                        {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
                        <a href="/researchPolls">
                            <img
                                src="/BrowseResearch.png"
                                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                                title="Browse Research"
                                alt="Browse Research"
                                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
                            />
                        </a>&nbsp;&nbsp;
                        {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
                        <a href="/researchCampaigns">
                            <img
                                src="/ResearchCampaigns.png"
                                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                                title="Research Campaigns"
                                alt="Research Campaigns"
                                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
                            />
                        </a>
                    </li>
                    <li style={{ display: 'flex', marginTop: '0px', marginBottom: '5px', justifyContent: 'left', color: 'black' }}>
                        <b>Trivia Groups</b>
                    </li>
                    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}>
                        <a href="/createTriviaGroup">
                            <img
                                src="/CreateTriviaGroup.png"
                                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                                title="Create Trivia Group"
                                alt="Create Trivia Group"
                                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
                            />
                        </a>&nbsp;&nbsp;
                        {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
                        <a href="/listTriviaGroups">
                            <img
                                src="/ListTriviaGroups.png"
                                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                                title="List Trivia Groups"
                                alt="List Trivia Groups"
                                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
                            />
                        </a>&nbsp;&nbsp;
                        {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
                        <a href="/ManageTriviaGroupPolls">
                            <img
                                src="/ManageTriviaGroupPoll.png"
                                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                                title="Manage Trivia Group Poll"
                                alt="Manage Trivia Group Poll"
                                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
                            />
                        </a>
                    </li>
                    <li style={{ display: 'flex', marginTop: '0px', marginBottom: '5px', justifyContent: 'left', color: 'black' }}>
                        <b>Opinion Groups</b>
                    </li>
                    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}>
                        <a href="/createOpinionGroup">
                            <img
                                src="/CreateOpinionGroup.png"
                                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                                title="Create Opinion Group"
                                alt="Create Opinion Group"
                                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
                            />
                        </a>&nbsp;&nbsp;
                        {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
                        <a href="/listOpinionGroups">
                            <img
                                src="/ListOpinionGroups.png"
                                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                                title="List Opinion Groups"
                                alt="List Opinion Groups"
                                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
                            />
                        </a>&nbsp;&nbsp;
                        {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
                        <a href="/ManageOpinionGroupPolls">
                            <img
                                src="/ManageOpinionGroupPoll.png"
                                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                                title="Manage Opinion Group Poll"
                                alt="Manage Opinion Group Poll"
                                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
                            />
                        </a>
                    </li>
                    <li style={{ display: 'flex', marginTop: '0px', marginBottom: '5px', justifyContent: 'left', color: 'black' }}>
                        <b>Research Groups</b>
                    </li>
                    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}>
                        <a href="/createResearchGroup">
                            <img
                                src="/CreateResearchGroup.png"
                                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                                title="Create Research Group"
                                alt="Create Research Group"
                                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
                            />
                        </a>&nbsp;&nbsp;
                        {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
                        <a href="/listResearchGroups">
                            <img
                                src="/ListResearchGroups.png"
                                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                                title="List Research Groups"
                                alt="List Research Groups"
                                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
                            />
                        </a>&nbsp;&nbsp;
                        {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
                        <a href="/ManageResearchGroupPolls">
                            <img
                                src="/ManageResearchGroupPoll.png"
                                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                                title="Manage Research Group Poll"
                                alt="Manage Research Group Poll"
                                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
                            />
                        </a>
                    </li>
                </ul>
            </div>

            {/* Main content */}
            <div className="main-content">
                <div className="header">
                    <div className="logo">AIPolls.Net Opinion Poll Results</div>
                    <div className="auth-buttons">
                        <button onClick={handleLogout}>
                            <img alt="Logout" title="Logout" src={logoutIcon} style={{ width: '25px', height: '25px' }} />
                        </button>
                        <button onClick={handleProfile}>
                            <img alt="Profile" title="Profile" src={profileIcon} style={{ width: '25px', height: '25px' }} />
                        </button>
                    </div>
                </div>

                <h2>Poll Results</h2>

                {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                <h3>{question}</h3>
                <p>Campaign ID: {opinionSessionId}</p>
                <p>Poll ID: {pollId}</p>
                <p>Author: {poll_author}</p>
                <p>Campaign Start Date: {campaignStartDate}</p>
                <p>Question: {question}</p>
                <p>Campaign Strength: {totalVotes} participants</p>

                {chartData ? (
                    <div>
                        <Bar
                            id="poll-results-chart"
                            data={chartData}
                            options={{
                                responsive: true,
                                scales: {
                                    x: {
                                        title: {
                                            display: true,
                                            text: 'Options',
                                        },
                                    },
                                    y: {
                                        beginAtZero: true,
                                        title: {
                                            display: true,
                                            text: 'Number of Votes',
                                        },
                                        ticks: {
                                            precision: 0, // Ensure integer values
                                        },
                                    },
                                },
                            }}
                        />
                    </div>
                ) : (
                    <p>Loading poll results...</p>
                )}
                <button onClick={handleDownloadReport} style={{ marginTop: '20px' }}>
                    Generate Report
                </button>
            </div>
        </div>
    );
};

export default withAuthCheck(ViewSingleOpinionResults);
