// src/auth/handleErrors.js
import { refreshToken } from './auth';  // Assuming refreshToken is implemented


const handleErrors = async (error, navigate, retryCallback = null, setErrorMessage = null) => {
  console.log("ENTER HANDLE ERRORS");
  if (error.response) {
    if (error.response.status === 401) {
      try {
        const newAccessToken = await refreshToken();
        if (newAccessToken && typeof retryCallback === 'function') {
          await retryCallback();  // Await the retryCallback if it's async
        } else {
          navigate('/login');  // Redirect to login if token refresh fails
        }
      } catch (refreshError) {
        if (typeof setErrorMessage === 'function') {
          setErrorMessage('Session expired. Please log in again.');
        }
        navigate('/login');  // Redirect to login if refresh itself fails
      }
    } else {
      const message = error.response.data.message || 'An error occurred.';
      if (typeof setErrorMessage === 'function') {
        setErrorMessage(message);
      }
      console.error(message);
    }
  } else {
    const message = 'No response received from server.';
    if (typeof setErrorMessage === 'function') {
      setErrorMessage(message);
    }
    console.error(message);
    // Instead of navigating to /dashboard, handle the error more appropriately
    // navigate('/error'); or simply show an error message
  }
};

export default handleErrors;


/*
const handleErrors = async (error, navigate, retryCallback = null, setErrorMessage = null) => {
  console.log(error);
  if (error.response) {
    if (error.response.status === 401) {
      try {
        const newAccessToken = await refreshToken();
        if (newAccessToken && retryCallback) {
          retryCallback();  // Retry the specific operation if a callback is provided
        } else {
          navigate('/login');  // Redirect to login if token refresh fails
        }
      } catch (refreshError) {
        if (setErrorMessage) {
          setErrorMessage('Session expired. Please log in again.');
        }
        navigate('/login');  // Redirect to login if refresh itself fails
      }
    } else {
      const message = error.response.data.message || 'An error occurred.';
      if (setErrorMessage) {
        setErrorMessage(message);
      }
      //navigate('/login');
    }
  } else {
    const message = 'No response received from server.';
    if (setErrorMessage) {
      setErrorMessage(message);
    }
    console.error(message);
    //navigate('/dashboard');
  }
};

export default handleErrors;
*/