/*
 * Copyright © 2024 AIPolls.Net. All rights reserved.
 * 
 * This software is the confidential and proprietary information of AIPolls.Net.
 * Only authorized personnel may access, modify, or deploy this software. Unauthorized 
 * access, use, or distribution outside the official repositories of AIPolls.Net is 
 * strictly prohibited.
 * 
 * The founding team and any future employees or contractors are bound by these rules.
 * 
 * This software is provided "AS IS," without any warranty, express or implied.
 */

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../api';
import withAuthCheck from '../hoc/withAuthCheck';
import handleErrors from '../auth/handleErrors';
import '../App.css';
import logoutIcon from '../logout_icon.png';
import profileIcon from '../profile_icon.png';
import logoIcon from '../aipolls_logo_1563x1563.png';

const Dashboard = () => {
  const [polls, setPolls] = useState([]);
  const [opinionPolls, setOpinionPolls] = useState([]);
  const [researchPolls, setResearchPolls] = useState([]);
  const [triviaCampaigns, setTriviaCampaigns] = useState([]);
  const [opinionCampaigns, setOpinionCampaigns] = useState([]);
  const [researchCampaigns, setResearchCampaigns] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  // Logout handler needs to be defined at the top level
  const handleLogout = async () => {
    try {
      await api.post('/logout'); // Call your backend logout endpoint
      localStorage.removeItem('access_token'); // Clear the access token from localStorage
      navigate('/login'); // Redirect to login after logout
    } catch (error) {
      handleErrors(error, navigate, null, setErrorMessage);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch polls
        const response = await api.get('/trivia_polls');
        setPolls(response.data);

        const opinionResponse = await api.get('/opinion_polls');
        setOpinionPolls(opinionResponse.data);

        const researchResponse = await api.get('/research_polls');
        setResearchPolls(researchResponse.data);

        const triviaCampaignsResponse = await api.get('/list_trivia_campaigns');
        setTriviaCampaigns(triviaCampaignsResponse.data);

        const opinionCampaignsResponse = await api.get('/opinion_campaigns');
        setOpinionCampaigns(opinionCampaignsResponse.data);

        const researchCampaignsResponse = await api.get('/research_campaigns');
        setResearchCampaigns(researchCampaignsResponse.data);
      } catch (error) {
        handleErrors(error, navigate, fetchData, setErrorMessage);
      }
    };

    fetchData();
  }, [navigate]);

  const handleProfile = () => {
    // Navigate back to the profile
    navigate('/profile');
  };

  return (
    <div className="container">
      {/* Sidebar Menu */}
      {/* Sidebar Menu */}
      <div className="sidebar">
        <div className="logo">
          <img alt="AIPolls.Net" title="AIPolls.Net" src={logoIcon} style={{ width: '90px', height: '90px' }} />
        </div>

        <ul style={{ display: 'flex', flexDirection: 'column', listStyleType: 'none', padding: 0, margin: 0 }}>
          <li style={{ display: 'flex', marginTop: '20px', marginBottom: '5px', justifyContent: 'left', color: 'black' }}>
            <b>Trivia Polls</b>
          </li>
          <li style={{ display: 'flex', marginTop: '0px', marginBottom: '15px', justifyContent: 'center' }}>
            <a href="/createTriviaPoll">
              <img
                src="/Trivia.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="Trivia Polls"
                alt="Trivia Polls"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>&nbsp;&nbsp;
            {/*</li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
            <a href="/triviaPolls">
              <img
                src="/BrowseTrivia.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="Browse Trivia"
                alt="Browse Trivia"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>&nbsp;&nbsp;
            {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
            <a href="/listTriviaCampaigns">
              <img
                src="/TriviaCampaigns.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="Trivia Campaigns"
                alt="Trivia Campaigns"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>
          </li>
          <li style={{ display: 'flex', marginTop: '0px', marginBottom: '5px', justifyContent: 'left', color: 'black' }}>
            <b>Opinion Polls</b>
          </li>
          <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}>
            <a href="/createOpinionPoll">
              <img
                src="/Opinions.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="Seek Opinions"
                alt="Seek Opinions"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>&nbsp;&nbsp;
            {/*</li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
            <a href="/opinionPolls">
              <img
                src="/BrowseOpinions.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="Browse Opinions"
                alt="Browse Opinions"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>&nbsp;&nbsp;
            {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
            <a href="/opinionCampaigns">
              <img
                src="/OpinionCampaigns.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="Opinion Campaigns"
                alt="Opinion Campaigns"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>
          </li>
          <li style={{ display: 'flex', marginTop: '0px', marginBottom: '5px', justifyContent: 'left', color: 'black' }}>
            <b>Market Research</b>
          </li>
          <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}>
            <a href="/createResearchPoll">
              <img
                src="/ResearchPoll.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="Market Research"
                alt="Market Research"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>&nbsp;&nbsp;
            {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
            <a href="/researchPolls">
              <img
                src="/BrowseResearch.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="Browse Research"
                alt="Browse Research"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>&nbsp;&nbsp;
            {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
            <a href="/researchCampaigns">
              <img
                src="/ResearchCampaigns.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="Research Campaigns"
                alt="Research Campaigns"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>
          </li>
          <li style={{ display: 'flex', marginTop: '0px', marginBottom: '5px', justifyContent: 'left', color: 'black' }}>
            <b>Trivia Groups</b>
          </li>
          <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}>
            <a href="/createTriviaGroup">
              <img
                src="/CreateTriviaGroup.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="Create Trivia Group"
                alt="Create Trivia Group"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>&nbsp;&nbsp;
            {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
            <a href="/listTriviaGroups">
              <img
                src="/ListTriviaGroups.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="List Trivia Groups"
                alt="List Trivia Groups"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>&nbsp;&nbsp;
            {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
            <a href="/ManageTriviaGroupPolls">
              <img
                src="/ManageTriviaGroupPoll.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="Manage Trivia Group Poll"
                alt="Manage Trivia Group Poll"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>
          </li>
          <li style={{ display: 'flex', marginTop: '0px', marginBottom: '5px', justifyContent: 'left', color: 'black' }}>
            <b>Opinion Groups</b>
          </li>
          <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}>
            <a href="/createOpinionGroup">
              <img
                src="/CreateOpinionGroup.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="Create Opinion Group"
                alt="Create Opinion Group"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>&nbsp;&nbsp;
            {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
            <a href="/listOpinionGroups">
              <img
                src="/ListOpinionGroups.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="List Opinion Groups"
                alt="List Opinion Groups"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>&nbsp;&nbsp;
            {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
            <a href="/ManageOpinionGroupPolls">
              <img
                src="/ManageOpinionGroupPoll.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="Manage Opinion Group Poll"
                alt="Manage Opinion Group Poll"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>
          </li>
          <li style={{ display: 'flex', marginTop: '0px', marginBottom: '5px', justifyContent: 'left', color: 'black' }}>
            <b>Research Groups</b>
          </li>
          <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}>
            <a href="/createResearchGroup">
              <img
                src="/CreateResearchGroup.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="Create Research Group"
                alt="Create Research Group"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>&nbsp;&nbsp;
            {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
            <a href="/listResearchGroups">
              <img
                src="/ListResearchGroups.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="List Research Groups"
                alt="List Research Groups"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>&nbsp;&nbsp;
            {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
            <a href="/ManageResearchGroupPolls">
              <img
                src="/ManageResearchGroupPoll.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="Manage Research Group Poll"
                alt="Manage Research Group Poll"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>
          </li>
        </ul>

      </div>



      {/* Main content */}
      <div className="main-content">
        <div className="header">
          <div className="logo">AIPolls.Net Analytics Dashboard</div>
          <div className="auth-buttons">
            <button onClick={handleLogout}>
              <img alt="Logout" title="Logout" src={logoutIcon} style={{ width: '25px', height: '25px' }} />
            </button>
            <button onClick={handleProfile}>
              <img alt="Profile" title="Profile" src={profileIcon} style={{ width: '25px', height: '25px' }} />
            </button>
          </div>
        </div>

        {/* Error message display */}
        {errorMessage && <p style={{ color: 'black' }}>{errorMessage}</p>}

        {/* Poll Statistics */}
        <div className="poll-statistics">
          <p><b>Trivia Polls</b>: {polls.length}</p>
          <p><b>Opinion Polls</b>: {opinionPolls.length}</p>
          <p><b>Research Polls</b>: {researchPolls.length}</p>
          <p><b>Trivia Campaigns</b>: {triviaCampaigns.length}</p>
          <p><b>Opinion Campaigns</b>: {opinionCampaigns.length}</p>
          <p><b>Research Campaigns</b>: {researchCampaigns.length}</p>
        </div>
      </div>
    </div>
  );
};

export default withAuthCheck(Dashboard);
