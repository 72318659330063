/*
 * Copyright © 2024 AIPolls.Net. All rights reserved.
 * 
 * This software is the confidential and proprietary information of AIPolls.Net.
 * Only authorized personnel may access, modify, or deploy this software. Unauthorized 
 * access, use, or distribution outside the official repositories of AIPolls.Net is 
 * strictly prohibited.
 * 
 * The founding team and any future employees or contractors are bound by these rules.
 * 
 * This software is provided "AS IS," without any warranty, express or implied.
 */

import axios from 'axios';

//const API_URL = process.env.REACT_APP_AUTHOR_URL;
const API_URL = ""; // don't need this!

// Create an Axios instance
const api = axios.create({
  baseURL: API_URL + '/api',
  withCredentials: true, // Include cookies (for refresh token)
});

// Add a request interceptor to include the access token in every request
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token'); // Access token from localStorage
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`; // Add access token to request headers
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor to handle expired access tokens
api.interceptors.response.use(
  (response) => {
    return response; // Pass through successful responses
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      // Token has expired, try to refresh the access token
      originalRequest._retry = true;

      try {
        const refreshResponse = await api.post('/refresh_token', {}, { withCredentials: true });
        const newAccessToken = refreshResponse.data.access_token;
        localStorage.setItem('access_token', newAccessToken); // Save new access token
        originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`; // Retry with new token
        return api(originalRequest); // Retry the original request
      } catch (refreshError) {
        console.error('Failed to refresh token', refreshError);
        // Redirect to login or logout the user if refresh token fails
        localStorage.removeItem('access_token');
        window.location.href = '/login'; // Redirect to login
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export default api;
