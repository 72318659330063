/*
 * Copyright © 2024 AIPolls.Net. All rights reserved.
 * 
 * This software is the confidential and proprietary information of AIPolls.Net.
 * Only authorized personnel may access, modify, or deploy this software. Unauthorized 
 * access, use, or distribution outside the official repositories of AIPolls.Net is 
 * strictly prohibited.
 * 
 * The founding team and any future employees or contractors are bound by these rules.
 * 
 * This software is provided "AS IS," without any warranty, express or implied.
 */

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../api'; // Your Axios instance
import withAuthCheck from '../hoc/withAuthCheck';
import handleErrors from '../auth/handleErrors';
import '../App.css'; // Import the PollTaker-specific CSS
import logoutIcon from '../logout_icon.png';
import profileIcon from '../profile_icon.png';
import logoIcon from '../aipolls_logo_1563x1563.png';

const Profile = () => {
  const [user, setUser] = useState({
    email: '',
    name: '',
    street: '',
    apt_suite: '',
    city: '',
    state: '',
    zip_code: '',
    country: '',
    mobile_phone: '',
    work_phone: '',
    company: '',
    job_title: ''
  });
  const [currentPassword, setCurrentPassword] = useState('');  // Current password
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [changePassword, setChangePassword] = useState(false); // Change password checkbox
  const [acceptedAgreement, setAcceptedAgreement] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleLogout = async () => {
    try {
      await api.post('/logout'); // Call your backend logout endpoint
      localStorage.removeItem('access_token'); // Clear the access token from localStorage
      navigate('/login'); // Redirect to login after logout
    } catch (error) {
      handleErrors(error, navigate, null, setErrorMessage);
    }
  };

  useEffect(() => {
    // Fetch user profile when the component mounts
    const fetchProfile = async () => {
      try {
        const response = await api.get('/profile');
        setUser(response.data);
        setErrorMessage('');
      } catch (error) {
        handleErrors(error, navigate, fetchProfile, setErrorMessage);
      }
    };

    fetchProfile();
  }, [navigate]); // Removed handleErrors from dependency array to avoid re-renders

  // Function to validate the password
  const validatePassword = (password) => {
    const passwordRulesMessage = `
1. Does not start with a numeral
2. Must have at least 1 uppercase character
3. Must have at least 1 special character from the following: ('$', '@', '^', '-', '_', '%', '?', '&', '!', '*', '(', ')', '[', ']', '{', '}', '|', '~', '<', '>', ';', ':', '+', '#')
4. Must not contain any space characters
5. Must not contain any control or non-printable characters
6. Must not contain your email address or name in any upper or lower case combinations
`;

    const specialChars = ['$', '@', '^', '-', '_', '%', '?', '&', '!', '*', '(', ')', '[', ']', '{', '}', '|', '~', '<', '>', ';', ':', '+', '#'];

    // Rule 1: Password must not start with a numeral
    if (/^\d/.test(password)) {
      //return 'Password must not start with a numeral';
      return `Password does not meet the following requirements: ${passwordRulesMessage}`;

    }

    // Rule 2: Must contain at least 1 uppercase letter
    if (!/[A-Z]/.test(password)) {
      //return 'Password must contain at least 1 uppercase letter';
      return `Password does not meet the following requirements: ${passwordRulesMessage}`;
    }

    // Rule 3: Must contain at least 1 special character
    if (!specialChars.some(char => password.includes(char))) {
      //return 'Password must contain at least 1 special character';
      return `Password does not meet the following requirements: ${passwordRulesMessage}`;
    }

    // Rule 4: Must not contain any space characters
    if (/\s/.test(password)) {
      //return 'Password must not contain spaces';
      return `Password does not meet the following requirements: ${passwordRulesMessage}`;
    }

    // Rule 5: Must not contain any control or non-printable characters
    if (/[\x00-\x1F\x7F]/.test(password)) {
      //return 'Password must not contain control or non-printable characters';
      return `Password does not meet the following requirements: ${passwordRulesMessage}`;
    }

    return null; // Password is valid
  };

  const handleProfileUpdate = async (e) => {
    e.preventDefault();

    if (changePassword && newPassword !== confirmPassword) {
      setErrorMessage('New passwords do not match.');
      return;
    }

    if (!acceptedAgreement) {
      setErrorMessage('You must accept the disclosure agreement.');
      return;
    }

    // Validate password
    const validationError = validatePassword(newPassword);
    if (validationError) {
      setErrorMessage(validationError);
      return;
    }

    try {
      const response = await api.put('/profile', {
        email: user.email,
        name: user.name,
        mobile_phone: user.mobile_phone,
        work_phone: user.work_phone,
        zip_code: user.zip_code,
        street: user.street,
        apt_suite: user.apt_suite,
        city: user.city,
        state: user.state,
        country: user.country,
        company: user.company,
        job_title: user.job_title,
        current_password: currentPassword,  // Send current password to backend
        new_password: changePassword ? newPassword : null,  // Send new password only if Change Password is checked
        change_password: changePassword,    // Send flag to indicate if password should be changed
        accepted_agreement: acceptedAgreement
      });

      setSuccessMessage('Profile updated successfully.');
      setErrorMessage('');
    } catch (error) {
      handleErrors(error, navigate, null, setErrorMessage);
    }
  };

  const handleCancel = () => {
    // Navigate back to the dashboard
    navigate('/dashboard');
  };

  const handleProfile = () => {
    // Navigate back to the profile
    navigate('/profile');
  };

  return (
    <div className="container">
      {/* Sidebar Menu */}
      <div className="sidebar">
        <div className="logo">
          {(
            <img alt="AIPolls.Net" title="AIPolls.Net" src={logoIcon} style={{ width: '100px', height: '100px' }} />
          )}
        </div>
        <ul style={{ display: 'flex', flexDirection: 'column', listStyleType: 'none', padding: 0, margin: 0 }}>
          <li style={{ display: 'flex', marginTop: '20px', marginBottom: '5px', justifyContent: 'left', color: 'black' }}>
            <b>Trivia Polls</b>
          </li>
          <li style={{ display: 'flex', marginTop: '0px', marginBottom: '15px', justifyContent: 'center' }}>
            <a href="/createTriviaPoll">
              <img
                src="/Trivia.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="Trivia Polls"
                alt="Trivia Polls"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>&nbsp;&nbsp;
            {/*</li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
            <a href="/triviaPolls">
              <img
                src="/BrowseTrivia.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="Browse Trivia"
                alt="Browse Trivia"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>&nbsp;&nbsp;
            {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
            <a href="/listTriviaCampaigns">
              <img
                src="/TriviaCampaigns.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="Trivia Campaigns"
                alt="Trivia Campaigns"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>
          </li>
          <li style={{ display: 'flex', marginTop: '0px', marginBottom: '5px', justifyContent: 'left', color: 'black' }}>
            <b>Opinion Polls</b>
          </li>
          <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}>
            <a href="/createOpinionPoll">
              <img
                src="/Opinions.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="Seek Opinions"
                alt="Seek Opinions"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>&nbsp;&nbsp;
            {/*</li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
            <a href="/opinionPolls">
              <img
                src="/BrowseOpinions.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="Browse Opinions"
                alt="Browse Opinions"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>&nbsp;&nbsp;
            {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
            <a href="/opinionCampaigns">
              <img
                src="/OpinionCampaigns.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="Opinion Campaigns"
                alt="Opinion Campaigns"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>
          </li>
          <li style={{ display: 'flex', marginTop: '0px', marginBottom: '5px', justifyContent: 'left', color: 'black' }}>
            <b>Market Research</b>
          </li>
          <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}>
            <a href="/createResearchPoll">
              <img
                src="/ResearchPoll.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="Market Research"
                alt="Market Research"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>&nbsp;&nbsp;
            {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
            <a href="/researchPolls">
              <img
                src="/BrowseResearch.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="Browse Research"
                alt="Browse Research"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>&nbsp;&nbsp;
            {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
            <a href="/researchCampaigns">
              <img
                src="/ResearchCampaigns.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="Research Campaigns"
                alt="Research Campaigns"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>
          </li>
          <li style={{ display: 'flex', marginTop: '0px', marginBottom: '5px', justifyContent: 'left', color: 'black' }}>
            <b>Trivia Groups</b>
          </li>
          <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}>
            <a href="/createTriviaGroup">
              <img
                src="/CreateTriviaGroup.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="Create Trivia Group"
                alt="Create Trivia Group"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>&nbsp;&nbsp;
            {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
            <a href="/listTriviaGroups">
              <img
                src="/ListTriviaGroups.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="List Trivia Groups"
                alt="List Trivia Groups"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>&nbsp;&nbsp;
            {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
            <a href="/ManageTriviaGroupPolls">
              <img
                src="/ManageTriviaGroupPoll.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="Manage Trivia Group Poll"
                alt="Manage Trivia Group Poll"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>
          </li>
          <li style={{ display: 'flex', marginTop: '0px', marginBottom: '5px', justifyContent: 'left', color: 'black' }}>
            <b>Opinion Groups</b>
          </li>
          <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}>
            <a href="/createOpinionGroup">
              <img
                src="/CreateOpinionGroup.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="Create Opinion Group"
                alt="Create Opinion Group"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>&nbsp;&nbsp;
            {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
            <a href="/listOpinionGroups">
              <img
                src="/ListOpinionGroups.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="List Opinion Groups"
                alt="List Opinion Groups"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>&nbsp;&nbsp;
            {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
            <a href="/ManageOpinionGroupPolls">
              <img
                src="/ManageOpinionGroupPoll.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="Manage Opinion Group Poll"
                alt="Manage Opinion Group Poll"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>
          </li>
          <li style={{ display: 'flex', marginTop: '0px', marginBottom: '5px', justifyContent: 'left', color: 'black' }}>
            <b>Research Groups</b>
          </li>
          <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}>
            <a href="/createResearchGroup">
              <img
                src="/CreateResearchGroup.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="Create Research Group"
                alt="Create Research Group"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>&nbsp;&nbsp;
            {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
            <a href="/listResearchGroups">
              <img
                src="/ListResearchGroups.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="List Research Groups"
                alt="List Research Groups"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>&nbsp;&nbsp;
            {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
            <a href="/ManageResearchGroupPolls">
              <img
                src="/ManageResearchGroupPoll.png"
                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                title="Manage Research Group Poll"
                alt="Manage Research Group Poll"
                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </a>
          </li>
        </ul>


      </div>

      {/* Main content */}
      <div className="main-content">
        <div className="header">
          <div className="logo">AIPolls.Net Analytics Dashboard</div>
          <div className="auth-buttons">
            {(
              <button onClick={handleLogout}>
                <img alt="Logout" title="Logout" src={logoutIcon} style={{ width: '25px', height: '25px' }} />
              </button>
            )}
            {(
              <button onClick={handleProfile}>
                <img alt="Profile" title="Profile" src={profileIcon} style={{ width: '25px', height: '25px' }} />
              </button>
            )}
          </div>
        </div>

        <div className="profile-section">
          <h2>Your Profile</h2>
          {errorMessage && <p style={{ color: 'black' }}>{errorMessage}</p>}
          {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}

          <form onSubmit={handleProfileUpdate}>
            {/* Name */}
            <div>
              <label>
                Name <span style={{ color: 'red' }}>*</span>:
                <input
                  type="text"
                  value={user.name}
                  onChange={(e) => setUser({ ...user, name: e.target.value })}
                  required
                />
              </label>
            </div>

            {/* Email */}
            <div>
              <label>
                Email <span style={{ color: 'red' }}>*</span>:
                <input
                  type="email"
                  value={user.email}
                  onChange={(e) => setUser({ ...user, email: e.target.value })}
                  required
                />
              </label>
            </div>

            {/* Mobile Phone */}
            <div>
              <label>
                Mobile Phone <span style={{ color: 'red' }}>*</span>:
                <input
                  type="text"
                  value={user.mobile_phone}
                  onChange={(e) => setUser({ ...user, mobile_phone: e.target.value })}
                  required
                />
              </label>
            </div>

            {/* Work Phone */}
            <div>
              <label>
                Work Phone:
                <input
                  type="text"
                  value={user.work_phone}
                  onChange={(e) => setUser({ ...user, work_phone: e.target.value })}
                />
              </label>
            </div>

            {/* Street */}
            <div>
              <label>
                Street:
                <input
                  type="text"
                  value={user.street}
                  onChange={(e) => setUser({ ...user, street: e.target.value })}
                />
              </label>
            </div>

            {/* Apt/Suite */}
            <div>
              <label>
                Apt/Suite:
                <input
                  type="text"
                  value={user.apt_suite}
                  onChange={(e) => setUser({ ...user, apt_suite: e.target.value })}
                />
              </label>
            </div>

            {/* City */}
            <div>
              <label>
                City:
                <input
                  type="text"
                  value={user.city}
                  onChange={(e) => setUser({ ...user, city: e.target.value })}
                />
              </label>
            </div>

            {/* State */}
            <div>
              <label>
                State:
                <input
                  type="text"
                  value={user.state}
                  onChange={(e) => setUser({ ...user, state: e.target.value })}
                />
              </label>
            </div>

            {/* Zip Code */}
            <div>
              <label>
                Zip Code <span style={{ color: 'red' }}>*</span>:
                <input
                  type="text"
                  value={user.zip_code}
                  onChange={(e) => setUser({ ...user, zip_code: e.target.value })}
                  required
                />
              </label>
            </div>

            {/* Country */}
            <div>
              <label>
                Country:
                <input
                  type="text"
                  value={user.country}
                  onChange={(e) => setUser({ ...user, country: e.target.value })}
                />
              </label>
            </div>

            {/* Company */}
            <div>
              <label>
                Company:
                <input
                  type="text"
                  value={user.company}
                  onChange={(e) => setUser({ ...user, company: e.target.value })}
                />
              </label>
            </div>

            {/* Job Title */}
            <div>
              <label>
                Job Title:
                <input
                  type="text"
                  value={user.job_title}
                  onChange={(e) => setUser({ ...user, job_title: e.target.value })}
                />
              </label>
            </div>

            {/* Current Password (mandatory for any profile changes) */}
            <div>
              <label>
                Current Password <span style={{ color: 'red' }}>*</span>:
                <input
                  type="password"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  required
                />
              </label>
            </div>

            {/* Change Password Checkbox */}
            <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
              <input
                type="checkbox"
                checked={changePassword}
                onChange={(e) => setChangePassword(e.target.checked)}
                id="change-password"
                style={{ marginRight: '10px' }}
              />
              <label htmlFor="change-password">
                Change Password
              </label>
            </div>

            {/* New Password */}
            <div>
              <label>
                New Password:
                <input
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  disabled={!changePassword}
                  required={changePassword} 
                />
              </label>
            </div>

            {/* Confirm New Password */}
            <div>
              <label>
                Confirm New Password:
                <input
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  disabled={!changePassword}  
                  required={changePassword} 
                />
              </label>
            </div>

            {/* Checkbox to accept the agreement */}
            <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
              <input
                type="checkbox"
                checked={acceptedAgreement}
                onChange={(e) => setAcceptedAgreement(e.target.checked)}
                id="accept-agreement"
                style={{ marginRight: '10px' }}
                required
              />
              <label htmlFor="accept-agreement">
              I accept the <a href={`${apiUrl}/disclosure_agreement`} target="_blank" rel="noopener noreferrer">disclosure agreement</a>
            </label>
            </div>

            {/* Submit button */}
            <button type="submit">Update Profile</button>
          </form>

          {/* Cancel button */}
          <button onClick={handleCancel} style={{ marginTop: '20px' }}>Cancel</button>
        </div>

      </div>
    </div>
  );
};

export default withAuthCheck(Profile);
