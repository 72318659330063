/*
 * Copyright © 2024 AIPolls.Net. All rights reserved.
 * 
 * This software is the confidential and proprietary information of AIPolls.Net.
 * Only authorized personnel may access, modify, or deploy this software. Unauthorized 
 * access, use, or distribution outside the official repositories of AIPolls.Net is 
 * strictly prohibited.
 * 
 * The founding team and any future employees or contractors are bound by these rules.
 * 
 * This software is provided "AS IS," without any warranty, express or implied.
 */

import React, { useState, useEffect } from 'react';
import api from '../api'; // Assuming you have set up Axios instance
import { useNavigate } from 'react-router-dom'; // Assuming you use this to navigate after login
import handleErrors from '../auth/handleErrors'; // Assuming you have this function to handle errors
import '../App.css';
import logoutIcon from '../logout_icon.png';
import profileIcon from '../profile_icon.png';
import logoIcon from '../aipolls_logo_1563x1563.png';
import slogan from '../aipolls_slogan.gif';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState(''); // Add OTP state
  const [errorMessage, setErrorMessage] = useState('');
  const [, setIsAuthenticated] = useState(false);
  const [signupMode, setSignupMode] = useState(false); // Add signup mode state
  const navigate = useNavigate(); // To redirect after login

  useEffect(() => {
    if (signupMode) {
      console.log('Redirecting to signup page');
      navigate('/signup'); // Redirect to signup page when signupMode is true
    }
  }, [signupMode, navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post('/login', { email, password, otp });
      // Handle successful login
      setIsAuthenticated(true);
      localStorage.setItem('access_token', response.data.access_token);
      console.log("Login successful");
      setErrorMessage('');
      navigate('/dashboard');
    } catch (error) {
      handleErrors(error, navigate, null, setErrorMessage);
    }
  };

  return (
    <div className="container">
      {/* Sidebar Menu */}
      <div className="sidebar">
        <div className="logo">
             {(
  <img alt="AIPolls.Net" title="AIPolls.Net" src={logoIcon} style={{ width: '100px', height: '100px' }} />
            )}
        </div>
<ul style={{ display: 'flex', flexDirection: 'column', listStyleType: 'none', padding: 0, margin: 0 }}>
    <li style={{ display: 'flex', marginTop: '20px', marginBottom: '5px', justifyContent: 'left', color: 'black' }}>
        <b>Trivia Polls</b>
    </li>
    <li style={{ display: 'flex', marginTop: '0px', marginBottom: '15px', justifyContent: 'center' }}>
      <a href="/createTriviaPoll">
        <img
          src="/Trivia.png"
          style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
          title="Trivia Polls"
          alt="Trivia Polls"
          onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
          onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
        />
      </a>&nbsp;&nbsp;
    {/*</li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
      <a href="/triviaPolls">
        <img
          src="/BrowseTrivia.png"
          style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
          title="Browse Trivia"
          alt="Browse Trivia"
          onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
          onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
        />
      </a>&nbsp;&nbsp;
    {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
      <a href="/listTriviaCampaigns">
        <img
          src="/TriviaCampaigns.png"
          style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
          title="Trivia Campaigns"
          alt="Trivia Campaigns"
          onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
          onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
        />
      </a>
    </li>
    <li style={{ display: 'flex', marginTop: '0px', marginBottom: '5px', justifyContent: 'left', color: 'black' }}>
        <b>Opinion Polls</b>
    </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}>
      <a href="/createOpinionPoll">
        <img
          src="/Opinions.png"
          style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
          title="Seek Opinions"
          alt="Seek Opinions"
          onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
          onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
        />
      </a>&nbsp;&nbsp;
    {/*</li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
      <a href="/opinionPolls">
        <img
          src="/BrowseOpinions.png"
          style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
          title="Browse Opinions"
          alt="Browse Opinions"
          onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
          onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
        />
      </a>&nbsp;&nbsp;
    {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
      <a href="/opinionCampaigns">
        <img
          src="/OpinionCampaigns.png"
          style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
          title="Opinion Campaigns"
          alt="Opinion Campaigns"
          onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
          onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
        />
      </a>
    </li>
    <li style={{ display: 'flex', marginTop: '0px', marginBottom: '5px', justifyContent: 'left', color: 'black' }}>
        <b>Market Research</b>
    </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}>
      <a href="/createResearchPoll">
        <img
          src="/ResearchPoll.png"
          style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
          title="Market Research"
          alt="Market Research"
          onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
          onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
        />
      </a>&nbsp;&nbsp;
    {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
      <a href="/researchPolls">
        <img
          src="/BrowseResearch.png"
          style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
          title="Browse Research"
          alt="Browse Research"
          onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
          onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
        />
      </a>&nbsp;&nbsp;
    {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
      <a href="/researchCampaigns">
        <img
          src="/ResearchCampaigns.png"
          style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
          title="Research Campaigns"
          alt="Research Campaigns"
          onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
          onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
        />
      </a>
    </li>
    <li style={{ display: 'flex', marginTop: '0px', marginBottom: '5px', justifyContent: 'left', color: 'black' }}>
        <b>Trivia Groups</b>
    </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}>
      <a href="/createTriviaGroup">
        <img
          src="/CreateTriviaGroup.png"
          style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
          title="Create Trivia Group"
          alt="Create Trivia Group"
          onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
          onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
        />
      </a>&nbsp;&nbsp;
    {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
      <a href="/listTriviaGroups">
        <img
          src="/ListTriviaGroups.png"
          style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
          title="List Trivia Groups"
          alt="List Trivia Groups"
          onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
          onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
        />
      </a>&nbsp;&nbsp;
    {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
      <a href="/ManageTriviaGroupPolls">
        <img
          src="/ManageTriviaGroupPoll.png"
          style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
          title="Manage Trivia Group Poll"
          alt="Manage Trivia Group Poll"
          onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
          onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
        />
      </a>
    </li>
    <li style={{ display: 'flex', marginTop: '0px', marginBottom: '5px', justifyContent: 'left', color: 'black' }}>
        <b>Opinion Groups</b>
    </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}>
      <a href="/createOpinionGroup">
        <img
          src="/CreateOpinionGroup.png"
          style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
          title="Create Opinion Group"
          alt="Create Opinion Group"
          onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
          onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
        />
      </a>&nbsp;&nbsp;
    {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
      <a href="/listOpinionGroups">
        <img
          src="/ListOpinionGroups.png"
          style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
          title="List Opinion Groups"
          alt="List Opinion Groups"
          onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
          onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
        />
      </a>&nbsp;&nbsp;
    {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
      <a href="/ManageOpinionGroupPolls">
        <img
          src="/ManageOpinionGroupPoll.png"
          style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
          title="Manage Opinion Group Poll"
          alt="Manage Opinion Group Poll"
          onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
          onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
        />
      </a>
    </li>
    <li style={{ display: 'flex', marginTop: '0px', marginBottom: '5px', justifyContent: 'left', color: 'black' }}>
        <b>Research Groups</b>
    </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}>
      <a href="/createResearchGroup">
        <img
          src="/CreateResearchGroup.png"
          style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
          title="Create Research Group"
          alt="Create Research Group"
          onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
          onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
        />
      </a>&nbsp;&nbsp;
    {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
      <a href="/listResearchGroups">
        <img
          src="/ListResearchGroups.png"
          style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
          title="List Research Groups"
          alt="List Research Groups"
          onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
          onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
        />
      </a>&nbsp;&nbsp;
    {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
      <a href="/ManageResearchGroupPolls">
        <img
          src="/ManageResearchGroupPoll.png"
          style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
          title="Manage Research Group Poll"
          alt="Manage Research Group Poll"
          onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
          onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
        />
      </a>
    </li>
  </ul>

      </div>

      {/* Main content */}
      <div className="main-content">
        <div className="header">
          <div className="logo">AIPolls.Net Analytics Dashboard</div>
          <div className="auth-buttons">
            {(
              <button>
  <img alt="Login" title="Login" src={logoutIcon} style={{ width: '25px', height: '25px' }} />
              </button>
            )}
            {(
              <button>
  <img alt="Profile" title="Profile" src={profileIcon} style={{ width: '25px', height: '25px' }} />
              </button>
            )}
          </div>
       </div>

       <h2>Login</h2>
      <form onSubmit={handleLogin}>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          style={{ display: 'block', marginBottom: '10px' }}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          style={{ display: 'block', marginBottom: '10px' }}
        />

        {/* OTP input, shown only if OTP is required */}
        <input
          type="text"
          placeholder="OTP"
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
          required
          style={{ display: 'block', marginBottom: '10px' }}
        />

        <button type="submit" style={{ display: 'block', marginBottom: '10px' }}>
          Login
        </button>
      </form>
      <button onClick={() => setSignupMode(true)} style={{ display: 'block' }}>
        Need an account? Signup
      </button>

      {/*}
      <h2>Login</h2>
      <form onSubmit={handleLogin}>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />

        {(
          <input
            type="text"
            placeholder="OTP"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            required
          />
        )}
        <button type="submit">Login</button>
      </form>
      <button onClick={() => setSignupMode(true)}>Need an account? Signup</button>
      {/* Display animated GIF below the form */}
      <div style={{ marginTop: '20px', textAlign: 'center' }}>
        <img
          src={slogan}
          alt="Signup Slogan"
          style={{ width: '300px', height: 'auto' }}
        />
      </div>
      {errorMessage && <p style={{ color: 'black' }}>{errorMessage}</p>}
     </div>
    </div>
  );
};

export default Login;
