/*
 * Copyright © 2024 AIPolls.Net. All rights reserved.
 * 
 * This software is the confidential and proprietary information of AIPolls.Net.
 * Only authorized personnel may access, modify, or deploy this software. Unauthorized 
 * access, use, or distribution outside the official repositories of AIPolls.Net is 
 * strictly prohibited.
 * 
 * The founding team and any future employees or contractors are bound by these rules.
 * 
 * This software is provided "AS IS," without any warranty, express or implied.
 */

import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../api'; // Assuming Axios instance is imported
import handleErrors from '../auth/handleErrors';
import withAuthCheck from '../hoc/withAuthCheck';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Bar } from 'react-chartjs-2';
import logoutIcon from '../logout_icon.png';
import profileIcon from '../profile_icon.png';
import logoIcon from '../aipolls_logo_1563x1563.png';
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';


// Register necessary components for Chart.js
Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const ViewResearchCampaign = () => {
    const [campaign, setCampaign] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [pollResults, setPollResults] = useState([]);
    const [correctCount, setCorrectCount] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [chartData, setChartData] = useState({});
    const [errorMessage, setErrorMessage] = useState('');
    const { sessionId, pollId } = useParams(); // Get the sessionId from the URL
    const navigate = useNavigate();

    const fetchCampaignDetails = useCallback(async () => {
        try {
            const response = await api.get(`/view_research_campaign/${sessionId}/${pollId}`); // Adjust to match your route
            const { campaign, pollResults } = response.data;

            setCampaign(campaign);
            setPollResults(pollResults);

            // Calculate total and correct answer counts
            const total = pollResults.length;
            if (total === 0) {
                setTotalCount(0);
                setCorrectCount(0);
            } else {
                const correct = pollResults.filter(result => result.correct).length;
                setTotalCount(total);
                setCorrectCount(correct);
            }

            // Prepare chart data (group results by answer)
            const answersDistribution = pollResults.reduce((acc, result) => {
                acc[result.answer] = (acc[result.answer] || 0) + 1;
                return acc;
            }, {});

            // Prepare chart data for Bar component
            setChartData({
                labels: Object.keys(answersDistribution),
                datasets: [
                    {
                        label: 'Number of Users',
                        data: Object.values(answersDistribution),
                        backgroundColor: Object.keys(answersDistribution).map(answer =>
                            answer === campaign.correct_answer ? 'green' : 'blue' // Mark correct answer as green
                        )
                    }
                ]
            });
        } catch (error) {
            await handleErrors(error, navigate, fetchCampaignDetails, setErrorMessage);
        }
    }, [sessionId, navigate, pollId]); // Adding the dependencies to the useCallback hook

    // This useEffect will update the campaign details every second
    useEffect(() => {
        fetchCampaignDetails(); // Initial fetch

        const intervalId = setInterval(() => {
            if (!errorMessage)
                fetchCampaignDetails(); // Fetch every 1 second
        }, 1000);

        // Cleanup function to clear the interval on unmount
        return () => clearInterval(intervalId);
    }, [errorMessage, fetchCampaignDetails, sessionId]);

    // Function to generate and download the PDF report
    const handleDownloadReport = async () => {
        // Generate the chart as an image
        const chartCanvas = document.getElementById('poll-results-chart');
        const chartImage = await html2canvas(chartCanvas).then((canvas) => canvas.toDataURL('image/png'));

        // Create a PDF document
        const doc = new jsPDF();

        // Add poll question and vote data to the PDF
        doc.text(`Poll Question: ${campaign.question}`, 10, 10);
        doc.text(`Poll ID: ${pollId}`, 10, 20);
        doc.text(`Opinion Campaign ID: ${campaign.opinion_session_id}`, 10, 30);
        doc.text(`Total Votes: ${totalCount}`, 10, 40);
        doc.text(`Report Author: ${campaign.name}`, 10, 50);
        doc.text(`Campaign Start Date: ${new Date(campaign.session_timestamp).toLocaleString()}`, 10, 60)

        // Add the chart image to the PDF
        doc.addImage(chartImage, 'PNG', 10, 50, 180, 100); // Position and size the chart

        // Save the PDF
        doc.save(`poll_results_${pollId}.pdf`);
    };

    const handleLogout = async () => {
        try {
            await api.post('/logout'); // Call your backend logout endpoint
            localStorage.removeItem('access_token'); // Clear the access token from localStorage
            navigate('/login'); // Redirect to login after logout
        } catch (error) {
            handleErrors(error, navigate, null, setErrorMessage);
        }
    };

    const handleProfile = () => {
        // Navigate to the profile page
        navigate('/profile');
    };

    return (
        <div className="container">
            {/* Sidebar Menu */}
            <div className="sidebar">
                <div className="logo">
                    <img alt="AIPolls.Net" title="AIPolls.Net" src={logoIcon} style={{ width: '100px', height: '100px' }} />
                </div>
                <ul style={{ display: 'flex', flexDirection: 'column', listStyleType: 'none', padding: 0, margin: 0 }}>
                    <li style={{ display: 'flex', marginTop: '20px', marginBottom: '5px', justifyContent: 'left', color: 'black' }}>
                        <b>Trivia Polls</b>
                    </li>
                    <li style={{ display: 'flex', marginTop: '0px', marginBottom: '15px', justifyContent: 'center' }}>
                        <a href="/createTriviaPoll">
                            <img
                                src="/Trivia.png"
                                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                                title="Trivia Polls"
                                alt="Trivia Polls"
                                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
                            />
                        </a>&nbsp;&nbsp;
                        {/*</li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
                        <a href="/triviaPolls">
                            <img
                                src="/BrowseTrivia.png"
                                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                                title="Browse Trivia"
                                alt="Browse Trivia"
                                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
                            />
                        </a>&nbsp;&nbsp;
                        {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
                        <a href="/listTriviaCampaigns">
                            <img
                                src="/TriviaCampaigns.png"
                                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                                title="Trivia Campaigns"
                                alt="Trivia Campaigns"
                                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
                            />
                        </a>
                    </li>
                    <li style={{ display: 'flex', marginTop: '0px', marginBottom: '5px', justifyContent: 'left', color: 'black' }}>
                        <b>Opinion Polls</b>
                    </li>
                    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}>
                        <a href="/createOpinionPoll">
                            <img
                                src="/Opinions.png"
                                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                                title="Seek Opinions"
                                alt="Seek Opinions"
                                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
                            />
                        </a>&nbsp;&nbsp;
                        {/*</li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
                        <a href="/opinionPolls">
                            <img
                                src="/BrowseOpinions.png"
                                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                                title="Browse Opinions"
                                alt="Browse Opinions"
                                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
                            />
                        </a>&nbsp;&nbsp;
                        {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
                        <a href="/opinionCampaigns">
                            <img
                                src="/OpinionCampaigns.png"
                                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                                title="Opinion Campaigns"
                                alt="Opinion Campaigns"
                                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
                            />
                        </a>
                    </li>
                    <li style={{ display: 'flex', marginTop: '0px', marginBottom: '5px', justifyContent: 'left', color: 'black' }}>
                        <b>Market Research</b>
                    </li>
                    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}>
                        <a href="/createResearchPoll">
                            <img
                                src="/ResearchPoll.png"
                                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                                title="Market Research"
                                alt="Market Research"
                                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
                            />
                        </a>&nbsp;&nbsp;
                        {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
                        <a href="/researchPolls">
                            <img
                                src="/BrowseResearch.png"
                                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                                title="Browse Research"
                                alt="Browse Research"
                                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
                            />
                        </a>&nbsp;&nbsp;
                        {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
                        <a href="/researchCampaigns">
                            <img
                                src="/ResearchCampaigns.png"
                                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                                title="Research Campaigns"
                                alt="Research Campaigns"
                                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
                            />
                        </a>
                    </li>
                    <li style={{ display: 'flex', marginTop: '0px', marginBottom: '5px', justifyContent: 'left', color: 'black' }}>
                        <b>Trivia Groups</b>
                    </li>
                    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}>
                        <a href="/createTriviaGroup">
                            <img
                                src="/CreateTriviaGroup.png"
                                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                                title="Create Trivia Group"
                                alt="Create Trivia Group"
                                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
                            />
                        </a>&nbsp;&nbsp;
                        {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
                        <a href="/listTriviaGroups">
                            <img
                                src="/ListTriviaGroups.png"
                                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                                title="List Trivia Groups"
                                alt="List Trivia Groups"
                                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
                            />
                        </a>&nbsp;&nbsp;
                        {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
                        <a href="/ManageTriviaGroupPolls">
                            <img
                                src="/ManageTriviaGroupPoll.png"
                                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                                title="Manage Trivia Group Poll"
                                alt="Manage Trivia Group Poll"
                                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
                            />
                        </a>
                    </li>
                    <li style={{ display: 'flex', marginTop: '0px', marginBottom: '5px', justifyContent: 'left', color: 'black' }}>
                        <b>Opinion Groups</b>
                    </li>
                    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}>
                        <a href="/createOpinionGroup">
                            <img
                                src="/CreateOpinionGroup.png"
                                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                                title="Create Opinion Group"
                                alt="Create Opinion Group"
                                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
                            />
                        </a>&nbsp;&nbsp;
                        {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
                        <a href="/listOpinionGroups">
                            <img
                                src="/ListOpinionGroups.png"
                                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                                title="List Opinion Groups"
                                alt="List Opinion Groups"
                                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
                            />
                        </a>&nbsp;&nbsp;
                        {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
                        <a href="/ManageOpinionGroupPolls">
                            <img
                                src="/ManageOpinionGroupPoll.png"
                                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                                title="Manage Opinion Group Poll"
                                alt="Manage Opinion Group Poll"
                                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
                            />
                        </a>
                    </li>
                    <li style={{ display: 'flex', marginTop: '0px', marginBottom: '5px', justifyContent: 'left', color: 'black' }}>
                        <b>Research Groups</b>
                    </li>
                    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}>
                        <a href="/createResearchGroup">
                            <img
                                src="/CreateResearchGroup.png"
                                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                                title="Create Research Group"
                                alt="Create Research Group"
                                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
                            />
                        </a>&nbsp;&nbsp;
                        {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
                        <a href="/listResearchGroups">
                            <img
                                src="/ListResearchGroups.png"
                                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                                title="List Research Groups"
                                alt="List Research Groups"
                                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
                            />
                        </a>&nbsp;&nbsp;
                        {/* </li>
    <li style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center' }}> */}
                        <a href="/ManageResearchGroupPolls">
                            <img
                                src="/ManageResearchGroupPoll.png"
                                style={{ width: '45px', height: '45px', transition: 'transform 0.3s ease' }}
                                title="Manage Research Group Poll"
                                alt="Manage Research Group Poll"
                                onMouseEnter={(e) => (e.target.style.transform = 'scale(1.5)')}
                                onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
                            />
                        </a>
                    </li>
                </ul>


            </div>

            {/* Main content */}
            <div className="main-content">
                <div className="header">
                    <div className="logo">AIPolls.Net Campaign Details</div>
                    <div className="auth-buttons">
                        <button onClick={handleLogout}>
                            <img alt="Logout" title="Logout" src={logoutIcon} style={{ width: '25px', height: '25px' }} />
                        </button>
                        <button onClick={handleProfile}>
                            <img alt="Profile" title="Profile" src={profileIcon} style={{ width: '25px', height: '25px' }} />
                        </button>
                    </div>
                </div>

                <h2>Campaign Details</h2>

                {errorMessage && <p style={{ color: 'black' }}>{errorMessage}</p>}

                {campaign ? (
                    <div>
                        <p><strong>Timestamp of Campaign:</strong> {new Date(campaign.session_timestamp).toLocaleString()}</p>
                        <p><strong>Campaign ID:</strong> {campaign.research_session_id}</p>
                        <p><strong>Poll ID:</strong> {pollId}</p>
                        <p><strong>Number of Poll Users:</strong> {totalCount}</p>
                        {totalCount !== 0 &&
                            <p><strong>Correct Answers:</strong> {correctCount} ({((correctCount / totalCount) * 100).toFixed(2)}%)</p>
                        }
                        <div>
                            <h3>Poll Result Chart</h3>
                            <Bar
                                id="poll-results-chart"
                                data={chartData}
                                options={{
                                    responsive: true,
                                    scales: {
                                        x: {
                                            title: {
                                                display: true,
                                                text: 'Options',
                                            },
                                        },
                                        y: {
                                            beginAtZero: true,
                                            title: {
                                                display: true,
                                                text: 'Number of Users',
                                            },
                                            ticks: {
                                                precision: 0, // Ensure integer values
                                            },
                                        },
                                    },
                                }}
                            />
                        </div>
                    </div>
                ) : (
                    <p>Loading campaign details...</p>
                )}
                <button onClick={handleDownloadReport} style={{ marginTop: '20px' }}>
                    Download Report
                </button>

            </div>
        </div >
    );
};

export default withAuthCheck(ViewResearchCampaign);