/*
 * Copyright © 2024 AIPolls.Net. All rights reserved.
 * 
 * This software is the confidential and proprietary information of AIPolls.Net.
 * Only authorized personnel may access, modify, or deploy this software. Unauthorized 
 * access, use, or distribution outside the official repositories of AIPolls.Net is 
 * strictly prohibited.
 * 
 * The founding team and any future employees or contractors are bound by these rules.
 * 
 * This software is provided "AS IS," without any warranty, express or implied.
 */

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../api';
import withAuthCheck from '../hoc/withAuthCheck'; // Assuming withAuthCheck HOC is implemented
import handleErrors from '../auth/handleErrors';

// Poll Author route for starting a poll
const StartResearchGroupPoll = () => {
  const { group_uuid } = useParams();
  const [qrCodeUrl, setQrCodeUrl] = useState('');  // Store QR code URL after signup
  const [ pollSessionId, setPollSessionId ] = useState('');
  const [ localIP, setLocalIP ] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [realTime, setRealTime] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch the specific poll
    const startPoll = async () => {
      try {
        console.log("START POLL: group_uuid = " + group_uuid);
        const response = await api.get(`/start_research_group_poll/${group_uuid}`);
        const qrCodeURL = response.data.qr_code_image;  // This contains the base64-encoded image
        setQrCodeUrl(qrCodeURL);  // Set the state with the base64-encoded QR code
        setPollSessionId(response.data.poll_session_id);
        setLocalIP(response.data.localIP);
        setRealTime(response.data.realTime);
        setErrorMessage('');
        //navigate('/dashboard');
      } catch (error) {
        handleErrors(error, navigate, startPoll, setErrorMessage);
      }
    };

    startPoll();
  }, [group_uuid, navigate, setLocalIP, setPollSessionId]); // Run the effect whenever the poll_id changes

  return (
    <div>
      {errorMessage ? (
        // Display the error message if there is one
        <div style={{ color: 'red' }}>
          <h2>Error:</h2>
          <p>{errorMessage}</p>
        </div>
      ) : (
        // Display the QR code and success message if no error
        <>
          <h2>Poll Started!</h2>
          <p>Share the poll with participants using the generated QR code.</p>
          {realTime && (
          <a 
              href={`http://${localIP}:3000/advanceResearchGroupPoll/${pollSessionId}`} 
              rel="noopener noreferrer"
              style={{
                display: 'inline-block',
                padding: '10px 20px',
                fontSize: '16px',
                color: '#fff',
                backgroundColor: '#007BFF', // Bootstrap primary color
                border: 'none',
                borderRadius: '5px',
                textDecoration: 'none',
                cursor: 'pointer',
                textAlign: 'center'
              }}
            >
              Advance To Next Research Group Poll
            </a>
            )}
          {qrCodeUrl && (
            <div>
              <h3>Scan the QR code</h3>
              <img src={qrCodeUrl} alt="QR code" style={{ width: '96px', height: '96px' }} />
            </div>
          )}
        </>
      )}
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
    </div>
  );

};

export default withAuthCheck(StartResearchGroupPoll);